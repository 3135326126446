import { inject, observer } from "mobx-react";
import { useState } from "react";
import toast from "react-hot-toast";
import CreatableSelect from "react-select/creatable";
import { createAskQuestion } from "../api.service";
import GenerateButton from "./GenerateButton";
import Select from "react-select";

const options = [
  {
    value:
      "Chief Complaint: What is the patient's primary reason for seeking treatment during this session?",
    label:
      "What is the patient's primary reason for seeking treatment during this session?",
  },
  {
    value:
      "Treatment Plan Changes: Are there any recommended modifications to the current treatment plan based on today's session?",
    label:
      "Are there any recommended modifications to the current treatment plan based on today's session?",
  },
  {
    value:
      "Assignments and Homework: What assignments or homework have been given to the patient between this session and the next?",
    label:
      "What assignments or homework have been given to the patient between this session and the next?",
  },
  {
    value:
      "Progress Assessment: How would you summarize the patient's progress since the last session?",
    label:
      "How would you summarize the patient's progress since the last session?",
  },
  {
    value:
      "Symptoms and Behaviors: Can you provide details on any specific symptoms or behaviors exhibited by the patient during today's session?",
    label:
      "Can you provide details on any specific symptoms or behaviors exhibited by the patient during today's session?",
  },
  {
    value:
      "Risk Assessment: Did the session reveal any new information or concerns related to the patient's safety or risk factors?",
    label:
      "Did the session reveal any new information or concerns related to the patient's safety or risk factors?",
  },
  {
    value:
      "Collaborative Care:	Have there been any collaborative efforts or consultations with other healthcare professionals regarding the patient's care?",
    label:
      "Have there been any collaborative efforts or consultations with other healthcare professionals regarding the patient's care?",
  },
  {
    value:
      "Patient Goals: Are there updates or revisions to the patient's short-term and long-term goals?",
    label:
      "Are there updates or revisions to the patient's short-term and long-term goals?",
  },
  {
    value:
      "Therapeutic Interventions: What therapeutic interventions were employed during the session, and how did the patient respond?",
    label:
      "What therapeutic interventions were employed during the session, and how did the patient respond?",
  },
  {
    value:
      "Follow-up Plans: What are the specific follow-up plans and recommendations for the patient before the next session?",
    label:
      "What are the specific follow-up plans and recommendations for the patient before the next session?",
  },
  {
    value:
      "Clinical Diagnosis Impression: Based on today's session, what is your impression of the patient continuing to meet ASAM criteria for their current level of care (if applicable) and/or DSM criteria for any mental health diagnosis (if applicable)?",
    value:
      "Based on today's session, what is your impression of the patient continuing to meet ASAM criteria for their current level of care (if applicable) and/or DSM criteria for any mental health diagnosis (if applicable)?",
  },
  {
    value:
      "Cravings Assessment: What is your impression of the patient's cravings (if applicable) during or following today's session?",
    label:
      "What is your impression of the patient's cravings (if applicable) during or following today's session?",
  },
  {
    value:
      "Medication adherence:	If discussed, have there been any challenges or improvements in the patient's adherence to prescribed medications since the last session?",
    label:
      "If discussed, have there been any challenges or improvements in the patient's adherence to prescribed medications since the last session?",
  },
  {
    value:
      "Social Support: What is the current status of the patient's social support network, and have there been any changes in their relationships or support systems?",
    label:
      "What is the current status of the patient's social support network, and have there been any changes in their relationships or support systems?",
  },
  {
    value:
      "Coping Strategies: Discuss any new or existing coping strategies that the patient is utilizing, and assess their effectiveness.",
    label:
      "Discuss any new or existing coping strategies that the patient is utilizing, and assess their effectiveness.",
  },
  {
    value:
      "Motivation for Change: Explore the patient's motivation for change and any shifts in their commitment to the treatment plan.",
    label:
      "Explore the patient's motivation for change and any shifts in their commitment to the treatment plan.",
  },
  {
    value:
      "Life Stressors: Identify and discuss any significant life stressors or changes that may impact the patient's overall well-being.",
    label:
      "Identify and discuss any significant life stressors or changes that may impact the patient's overall well-being.",
  },
  {
    value:
      "Feedback on Therapy: Ask for the patient's feedback on the therapeutic process and if there are any adjustments they would like to suggest.",
    label:
      "Ask for the patient's feedback on the therapeutic process and if there are any adjustments they would like to suggest.",
  },
  {
    value:
      "Cultural Considerations: Consider any cultural factors that may be influencing the patient's experience or response to treatment.",
    label:
      "Consider any cultural factors that may be influencing the patient's experience or response to treatment.",
  },
  {
    value:
      "Self-Care Practices: Inquire about the patient's self-care practices and whether there have been any changes in their self-care routine.",
    label:
      "Inquire about the patient's self-care practices and whether there have been any changes in their self-care routine.",
  },
  {
    value:
      "Future Planning: Discuss any future plans or goals the patient has and how these align with their treatment objectives.",
    label:
      "Discuss any future plans or goals the patient has and how these align with their treatment objectives.",
  },
];

const categories = [
  {
    label: "Custom",
    value: "",
  },
  {
    label: "Chief Complaint",
    value:
      "What is the patient's primary reason for seeking treatment during this session?",
  },
  {
    label: "Treatment Plan Changes",
    value:
      "Are there any recommended modifications to the current treatment plan based on today's session?",
  },
  {
    label: "Assignments and Homework",
    value:
      "What assignments or homework have been given to the patient between this session and the next?",
  },
  {
    label: "Progress Assessment",
    value:
      "How would you summarize the patient's progress since the last session?",
  },
  {
    label: "Symptoms and Behaviors",
    value:
      "Can you provide details on any specific symptoms or behaviors exhibited by the patient during today's session?",
  },
  {
    label: "Risk Assessment",
    value:
      "Did the session reveal any new information or concerns related to the patient's safety or risk factors?",
  },
  {
    label: "Collaborative Care",
    value:
      "Have there been any collaborative efforts or consultations with other healthcare professionals regarding the patient's care?",
  },
  {
    label: "Patient Goals",
    value:
      "Are there updates or revisions to the patient's short-term and long-term goals?",
  },
  {
    label: "Therapeutic Interventions",
    value:
      "What therapeutic interventions were employed during the session, and how did the patient respond?",
  },
  {
    label: "Follow-up Plans",
    value:
      "What are the specific follow-up plans and recommendations for the patient before the next session?",
  },
  {
    label: "Clinical Diagnosis Impression",
    value:
      "Based on today's session, what is your impression of the patient continuing to meet ASAM criteria for their current level of care (if applicable) and/or DSM criteria for any mental health diagnosis (if applicable)?",
  },
  {
    label: "Cravings Assessment",
    value:
      "What is your impression of the patient's cravings (if applicable) during or following today's session?",
  },
  {
    label: "Medication adherence",
    value:
      "If discussed, have there been any challenges or improvements in the patient's adherence to prescribed medications since the last session?",
  },
  {
    label: "Social Support",
    value:
      "What is the current status of the patient's social support network, and have there been any changes in their relationships or support systems?",
  },
  {
    label: "Coping Strategies",
    value:
      "Discuss any new or existing coping strategies that the patient is utilizing, and assess their effectiveness.",
  },
  {
    label: "Motivation for Change",
    value:
      "Explore the patient's motivation for change and any shifts in their commitment to the treatment plan.",
  },
  {
    label: "Life Stressors",
    value:
      "Identify and discuss any significant life stressors or changes that may impact the patient's overall well-being.",
  },
  {
    label: "Feedback on Therapy",
    value:
      "Ask for the patient's feedback on the therapeutic process and if there are any adjustments they would like to suggest.",
  },
  {
    label: "Cultural Considerations",
    value:
      "Consider any cultural factors that may be influencing the patient's experience or response to treatment.",
  },
  {
    label: "Self-Care Practices",
    value:
      "Inquire about the patient's self-care practices and whether there have been any changes in their self-care routine.",
  },
  {
    label: "Future Planning",
    value:
      "Discuss any future plans or goals the patient has and how these align with their treatment objectives.",
  },
];

const DropdownIndicator = () => null;

const AskAQuestion = inject(
  "store",
  "navixScribeStore"
)(
  observer(({ store, navixScribeStore }) => {
    const [question, setQuestion] = useState({ label: "", value: "" });
    const [customQuestion, setCustomQuestion] = useState("");

    const handleGenerateAnswer = async () => {
      if (
        !navixScribeStore.inputActiveRecord.output &&
        !navixScribeStore.inputActiveRecord.outputs
      ) {
        return toast.error("Please select input first!");
      }

      const selectedQuestion = question.value || customQuestion;
      if (!selectedQuestion) {
        return toast.error("Please select or type in your question");
      }

      const { data } = await createAskQuestion(
        navixScribeStore.inputActiveRecord.output
          ? navixScribeStore.inputActiveRecord.output
          : navixScribeStore.inputActiveRecord.outputs.toString(),
        selectedQuestion
      );
      navixScribeStore.setOutputActiveRecord(data.history);
      toast.success("Successfully generated answer.");
      navixScribeStore.setTriggerSection("AskQuestion");
    };

    const handleChangeCategory = (e) => {
      setQuestion(e);
      setCustomQuestion("");
    };

    const handleCustomQuestionChange = (e) => {
      setCustomQuestion(e.target.value);
    };

    return (
      <>
        <div className="py-2">
          <div className="flex flex-col">
            <label htmlFor="category" className="text-xs text-gray-500">
              Category
            </label>
            <Select
              defaultValue={categories[1]}
              isSearchable
              onChange={handleChangeCategory}
              options={categories}
            />
            <label htmlFor="question" className="text-xs text-gray-500">
              Question
            </label>
            <textarea
              name="question"
              id="question"
              className="p-1 rounded"
              rows={4}
              cols={4}
              value={question.value ? question.value : customQuestion}
              onChange={handleCustomQuestionChange}
              disabled={!!question.value}
            ></textarea>
          </div>
        </div>

        <GenerateButton generateFn={handleGenerateAnswer} />
      </>
    );
  })
);

export default AskAQuestion;
