const api = `/histories/navix-scribe`;
import { navixScribeStore } from "./NavixScribeStore";

const logResponse = (response) => {
  console.log("Response:", response);
};

export async function fetchPersonas() {
  const res = await store.api.get("/personas");
  return res.data;
}

export async function fetchHistories(
  currentPage = 1,
  toolType,
  toolAction,
  activeButton
) {
  const res = await store.api.get(
    `${api}?page=${currentPage}&toolType=${toolType}&toolAction=${toolAction}&isArchived=${activeButton}`
  );
  return res.data;
}

export async function updateHistory(id, payload) {
  return await store.api.put(`/histories/navix-scribe/${id}`, { ...payload });
}

//#region Input Tool API
export async function transcribeAudio(file, audioDuration) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("audioDuration", audioDuration);

  const response = await store.api.post(
    "/ai/audio/transcribe-audio",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  logResponse(response);
  return response;
}

export async function saveText(text, file) {
  const formData = new FormData();
  formData.append("text", text);

  if (file.type == "application/pdf") {
    formData.append("file", file);
  }

  const response = await store.api.post(
    "/ai/navixscribe/input/save-text",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  logResponse(response);
  return response;
}

export async function retranscribeAudio(transcriptId) {
  const response = await store.api.post(
    `/ai/audio/manual-retranscribe/${transcriptId}`
  );
  logResponse(response);
  return response;
}
//#endregion

//#region Decode text file
export async function decodeTextFile(file) {
  const formData = new FormData();
  formData.append("file", file);
  const response = await fetch("https://api.navix.ai/api/v1/files/upload", {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Something went wrong");
  }

  const data = await response.json();
  logResponse(data);
  return data;
}
//#endregion

export async function createClinicalNotes(historyId, format) {
  const response = await store.api.post("/ai/navixscribe/clinical-notes", {
    historyId,
    format,
    persona: navixScribeStore.selectedPersona?.value,
  });
  logResponse(response);
  return response;
}

export async function createAskQuestion(input, question) {
  const response = await store.api.post("/ai/navixscribe/ask-a-question", {
    input,
    question,
    persona: navixScribeStore.selectedPersona?.value,
  });
  logResponse(response);
  return response;
}

export async function createSummary(input, payload) {
  console.log("createSummary - Input:", input);
  console.log("createSummary - Payload:", payload);

  const response = await store.api.post("/ai/navixscribe/create-summary", {
    input,
    ...payload,
    persona: navixScribeStore.selectedPersona?.value,
  });

  console.log("createSummary - Response:", response);

  return response;
}

export async function renameSpeakers(historyId, speakers) {
  const response = await store.api.put("/ai/navixscribe/rename-speakers", {
    historyId,
    speakers,
  });
  logResponse(response);
  return response;
}

export async function checkAccountStatus() {
  const response = await store.api.get("/user/status");
  logResponse(response);
  return response.data;
}
