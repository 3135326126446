import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { CogIcon } from "@heroicons/react/solid";
import toast from "react-hot-toast";

const SettingsModal = ({ isOpen, onClose, navixScribeV2Store }) => {
  const [selectedTab, setSelectedTab] = useState("persona");
  const [selectedPersona, setSelectedPersona] = useState("");
  const [customResponse, setCustomResponse] = useState("");
  const [selectedPronouns, setSelectedPronouns] = useState("");
  const [selectedNoteStyle, setSelectedNoteStyle] = useState("");
  const [selectedPatientName, setSelectedPatientName] = useState("");
  const modalRef = useRef(null);

  const personaOptions = [
    {
      name: "Addiction Counselor",
      description:
        "Adept at tailoring recovery plans and preventing relapse in substance addiction cases.",
    },
    {
      name: "Case Manager",
      description:
        "Skilled in coordinating care plans, liaising with service providers, and tracking client progress and challenges.",
    },
    {
      name: "Doctor of Osteopathic Medicine",
      description:
        "Skilled in blending holistic and musculoskeletal insights with traditional medical practices for comprehensive patient care.",
    },
    {
      name: "Medical Doctor",
      description:
        "Focused on thorough medical assessments and interventions, integrating physical and mental health aspects in patient care.",
    },
    {
      name: "Nurse",
      description:
        "Concentrated on physical assessments, medication management, and monitoring physiological changes, with a focus on holistic patient well-being.",
    },
    {
      name: "Psychiatric Nurse Practitioner",
      description:
        "Specializing in psychiatric assessments and medication management, with a collaborative approach to holistic patient care.",
    },
    {
      name: "Psychiatrist",
      description:
        "Focused on psychiatric evaluations, medication management, and the biological aspects of mental health.",
    },
    {
      name: "Psychologist",
      description:
        "Centered on in-depth psychological assessments, therapeutic interventions, and understanding cognitive and emotional dynamics.",
    },
    {
      name: "Social Worker",
      description:
        "Focused on addressing social determinants of health, advocating for resources, and collaborating with community services for holistic client support.",
    },
    {
      name: "Therapist",
      description:
        "Concentrated on facilitating therapeutic dialogues, employing interventions, and tracking progress in emotional and relational aspects.",
    },
    { name: "Custom", description: "" },
  ];

  useEffect(() => {
    if (isOpen) {
      navixScribeV2Store.loadSelectedPersona();
      const savedPersona = navixScribeV2Store.selectedPersona;
      if (savedPersona) {
        setSelectedPersona(savedPersona.persona);
        setCustomResponse(savedPersona.customResponse);
      }
      setSelectedPronouns(navixScribeV2Store.getPronouns());
      setSelectedNoteStyle(navixScribeV2Store.getNoteStyle());
      setSelectedPatientName(navixScribeV2Store.getPatientName());
    }
  }, [isOpen, navixScribeV2Store]);

  const handleSave = () => {
    if (selectedPersona) {
      const personaData = {
        persona: selectedPersona,
        customResponse:
          selectedPersona === "Custom"
            ? customResponse
            : personaOptions.find((option) => option.name === selectedPersona)
                .description,
      };
      navixScribeV2Store.setSelectedPersona(personaData);
    }
    navixScribeV2Store.setPronouns(selectedPronouns);
    navixScribeV2Store.setNoteStyle(selectedNoteStyle);
    navixScribeV2Store.setPatientName(selectedPatientName);
    toast.success("Settings saved successfully!");
  };

  const handleClear = (tab) => {
    let settingName = "";
    switch (tab) {
      case "persona":
        navixScribeV2Store.clearSetting("selectedPersona");
        setSelectedPersona("");
        setCustomResponse("");
        settingName = "Persona";
        break;
      case "pronouns":
        navixScribeV2Store.clearSetting("selectedPronouns");
        setSelectedPronouns("");
        settingName = "Pronouns";
        break;
      case "noteStyle":
        navixScribeV2Store.clearSetting("selectedNoteStyle");
        setSelectedNoteStyle("");
        settingName = "Note Style";
        break;
      case "patientNames":
        navixScribeV2Store.clearSetting("selectedPatientName");
        setSelectedPatientName("");
        settingName = "Patient Names";
        break;
    }
    toast.success(`Setting for ${settingName} cleared successfully!`);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    isOpen && (
      <ModalOverlay>
        <ModalContent ref={modalRef}>
          <ModalHeader>
            <CogIcon className="h-6 w-6 mr-2" />
            Settings
          </ModalHeader>
          <TabNavigation>
            <Tab
              onClick={() => setSelectedTab("persona")}
              active={selectedTab === "persona"}
            >
              Select Persona
            </Tab>
            <Tab
              onClick={() => setSelectedTab("pronouns")}
              active={selectedTab === "pronouns"}
            >
              Select Pronouns
            </Tab>
            <Tab
              onClick={() => setSelectedTab("noteStyle")}
              active={selectedTab === "noteStyle"}
            >
              Note Style
            </Tab>
            <Tab
              onClick={() => setSelectedTab("patientNames")}
              active={selectedTab === "patientNames"}
            >
              Patient Names
            </Tab>
          </TabNavigation>
          <ModalBody>
            {selectedTab === "persona" && (
              <>
                <SelectWrapper>
                  <Label htmlFor="persona-select">Select Persona</Label>
                  <Select
                    id="persona-select"
                    value={selectedPersona}
                    onChange={(e) => {
                      const selected = e.target.value;
                      setSelectedPersona(selected);
                      const selectedOption = personaOptions.find(
                        (option) => option.name === selected
                      );
                      setCustomResponse(
                        selectedOption ? selectedOption.description : ""
                      );
                    }}
                    placeholder="Select a persona"
                  >
                    <option value="" disabled hidden>
                      Select a persona
                    </option>
                    {personaOptions.map((option) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </Select>
                </SelectWrapper>
                <TextAreaWrapper>
                  <Label htmlFor="custom-response">
                    How would you like NavixScribe to respond?
                  </Label>
                  <TextArea
                    id="custom-response"
                    value={customResponse}
                    onChange={(e) => setCustomResponse(e.target.value)}
                    placeholder="Enter custom response..."
                  />
                </TextAreaWrapper>
              </>
            )}
            {selectedTab === "pronouns" && (
              <SelectWrapper>
                <Label htmlFor="pronouns-select">
                  Which pronouns do you prefer?
                </Label>
                <Select
                  id="pronouns-select"
                  value={selectedPronouns}
                  onChange={(e) => setSelectedPronouns(e.target.value)}
                >
                  <option value="" disabled hidden>
                    Select pronouns
                  </option>
                  <option value="He/She">He/She</option>
                  <option value="They/Them">They/Them</option>
                </Select>
              </SelectWrapper>
            )}
            {selectedTab === "noteStyle" && (
              <SelectWrapper>
                <Label htmlFor="note-style-select">
                  Which note style do you prefer?
                </Label>
                <Select
                  id="note-style-select"
                  value={selectedNoteStyle}
                  onChange={(e) => setSelectedNoteStyle(e.target.value)}
                >
                  <option value="" disabled hidden>
                    Select note style
                  </option>
                  <option value="Bullet-point">Bullet-point</option>
                  <option value="Narrative">Narrative</option>
                </Select>
              </SelectWrapper>
            )}
            {selectedTab === "patientNames" && (
              <SelectWrapper>
                <Label htmlFor="patient-names-select">
                  Replace patient names with:
                </Label>
                <Select
                  id="patient-names-select"
                  value={selectedPatientName}
                  onChange={(e) => setSelectedPatientName(e.target.value)}
                >
                  <option value="" disabled hidden>
                    Select replacement
                  </option>
                  <option value="Patient">Patient</option>
                  <option value="Client">Client</option>
                </Select>
              </SelectWrapper>
            )}
          </ModalBody>
          <ButtonContainer>
            <ClearButton onClick={() => handleClear(selectedTab)}>
              Clear
            </ClearButton>
            <ConfirmButton onClick={handleSave}>Save</ConfirmButton>
          </ButtonContainer>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalContent>
      </ModalOverlay>
    )
  );
};

const TabNavigation = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  border-bottom: ${(props) => (props.active ? "2px solid #6200ea" : "none")};
  color: ${(props) => (props.active ? "#6200ea" : "#000")};
`;

const ClearButton = styled.button`
  background: none;
  border: none;
  color: #6200ea;
  cursor: pointer;
  margin-top: 10px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.h2`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Label = styled.label`
  font-weight: normal;
`;

const Select = styled.select`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 0.65em auto;

  &:invalid {
    color: gray;
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 100px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ConfirmButton = styled.button`
  background-color: #6200ea;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #3700b3;
  }

  &:disabled {
    background-color: #bdbdbd;
    cursor: not-allowed;
  }
`;

export default SettingsModal;
