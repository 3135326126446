import React, { useState } from "react";
import {
  SvgAppleLogo,
  SvgGooglePlayText,
  SvgNavixSvg,
  SvgPlaystoreIcon,
} from "../Icons";
import { XIcon } from "@heroicons/react/outline";
import "./FloatingBanner.css";

const FloatingBanner = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggle = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    localStorage.setItem("isCollapsed", newCollapsedState);
  };

  return (
    <div className="fixed z-50 bottom-9 right-10 flex items-center">
      <div
        className={`floating-container relative border-2 shadow-md p-3.5 bg-white ${
          isCollapsed
            ? "floating-collapsed rounded-full"
            : "floating-expanded rounded-[5px]"
        }`}
        style={
          !isCollapsed
            ? {
                borderImage:
                  "radial-gradient(circle at bottom left, #4336AB 10%, #845ACB 20%, #D7CBFF 100%, #FFFFFF26 100%) 1",
              }
            : {}
        }
        onClick={handleToggle}
      >
        {isCollapsed ? (
          <SvgNavixSvg width={43} height={43} />
        ) : (
          <div className="flex">
            <div className="mr-3">
              <SvgNavixSvg width={43} height={43} />
            </div>
            <div className="flex flex-col justify-start">
              <div>
                <span className="text-violet-900 text-base font-semibold block">
                  Download our app
                </span>
              </div>
              <div className="flex gap-2">
                <a
                  href="https://play.google.com/store/apps/details?id=com.navixhealth.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-[90px] h-[30px] bg-black rounded border border-neutral-400 flex items-center px-1.5"
                >
                  <SvgPlaystoreIcon width={17} height={18} />
                  <div className="flex flex-col ml-0.5">
                    <span className="text-white text-xxs leading-none">
                      GET IT ON
                    </span>
                    <SvgGooglePlayText width={56} height={12} />
                  </div>
                </a>
                <a
                  href="https://apps.apple.com/app/navix-ai/id6504450512"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-[90px] h-[30px] bg-black rounded border border-neutral-400 flex items-center px-1.5"
                >
                  <SvgAppleLogo width={16} height={18} />
                  <div className="flex flex-col ml-1.5">
                    <span className="text-white text-xxs leading-none">
                      Download on the
                    </span>
                    <span className="text-white text-xs leading-none">
                      App Store
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <button
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
              onClick={(e) => {
                e.stopPropagation();
                setIsCollapsed(true);
              }}
            >
              <XIcon className="w-4 h-4" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FloatingBanner;
