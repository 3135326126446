import { inject, observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import LoadingOverlay from "../../../components/LoadingOverlay";
import {
  getClinicalNotesCustomTemplates,
  generateClinicalNotesWithCustomTemplate,
  generateClinicalNotes,
} from "../api.service";
import ReactMarkdown from "react-markdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DuplicateIcon, DocumentTextIcon } from "@heroicons/react/outline";
import TOOLS from "../../../tools";
import moment from "moment";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const tool = TOOLS.find((tool) => tool.title === "Note Writer");
const noteFormatOptions = tool.prompts[0].prompts.find(
  (prompt) => prompt.title === "Note Format"
).options;
const primaryDiagnosisOptions = tool.prompts[0].prompts.find(
  (prompt) => prompt.title === "Primary Diagnosis"
).options;
const secondaryDiagnosisOptions = tool.prompts[0].prompts.find(
  (prompt) => prompt.title === "Secondary Diagnosis"
).options;
const tertiaryDiagnosisOptions = tool.prompts[0].prompts.find(
  (prompt) => prompt.title === "Tertiary Diagnosis"
).options;
const moodOptions = tool.prompts[0].prompts.find(
  (prompt) => prompt.title === "Mood"
).options;
const affectOptions = tool.prompts[0].prompts.find(
  (prompt) => prompt.title === "Affect"
).options;
const physicalOptions = tool.prompts[0].prompts.find(
  (prompt) => prompt.title === "Physical"
).options;
const behaviorOptions = tool.prompts[0].prompts.find(
  (prompt) => prompt.title === "Behavior"
).options;
const speechOptions = tool.prompts[0].prompts.find(
  (prompt) => prompt.title === "Speech"
).options;
const progressionOptions = tool.prompts[0].prompts.find(
  (prompt) => prompt.title === "Progression"
).options;

const selectStyle = {
  control: (base) => ({
    ...base,
    fontSize: "12px",
  }),
  menu: (base) => ({
    ...base,
    fontSize: "12px",
  }),
};

const ClinicalNoteForm = inject("clinicalNoteWriterStore")(
  observer(({ clinicalNoteWriterStore }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
      clientName: "",
      noteDate: moment().format("YYYY-MM-DD"),
      duration: "",
      noteFormat: "",
      primaryDiagnosis: "",
      secondaryDiagnosis: "",
      tertiaryDiagnosis: "",
      mood: [],
      affect: [],
      physical: [],
      behavior: [],
      speech: [],
      progression: [],
      sessionNotes: "",
    });
    const [customTemplates, setCustomTemplates] = useState([]);

    useEffect(() => {
      const fetchCustomTemplates = async () => {
        const templates = (await getClinicalNotesCustomTemplates()).data
          ?.templates;
        setCustomTemplates(
          templates.map((template) => ({
            value: template._id,
            label: `${template.title} (Custom)`,
          }))
        );
      };
      fetchCustomTemplates();
    }, []);

    const handleChange = (selectedOption, { name }) => {
      setFormData({
        ...formData,
        [name]: selectedOption.value,
      });
    };

    const handleMultiChange = (selectedOptions, { name }) => {
      setFormData({
        ...formData,
        [name]: selectedOptions.map((option) => option.value),
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      const formDataCopy = { ...formData };
      for (const key in formDataCopy) {
        if (Array.isArray(formDataCopy[key])) {
          formDataCopy[key] = formDataCopy[key].join(",");
        }
      }

      try {
        if (
          customTemplates.find(
            (template) => template.value === formData.noteFormat
          )
        ) {
          const response =
            await generateClinicalNotesWithCustomTemplate(formDataCopy);
          clinicalNoteWriterStore.setClinicalNoteOutput(response.data.output);
          clinicalNoteWriterStore.setHistories([
            {
              clientName: formData.clientName,
              noteDate: formData.noteDate,
              duration: formData.duration,
              output: response.data.output,
            },
            ...clinicalNoteWriterStore.histories,
          ]);
        } else {
          const response = await generateClinicalNotes(formDataCopy);
          clinicalNoteWriterStore.setClinicalNoteOutput(response.data.output);
          clinicalNoteWriterStore.setHistories([
            {
              clientName: formData.clientName,
              noteDate: formData.noteDate,
              duration: formData.duration,
              output: response.data.output,
            },
            ...clinicalNoteWriterStore.histories,
          ]);
        }
        setLoading(false);
      } catch {
        setLoading(false);
        toast.error("Error generating clinical note");
      }
    };

    const renderers = {
      strong: ({ children }) => {
        const headerText = Array.isArray(children)
          ? children.join("")
          : children;
        const startIndex =
          clinicalNoteWriterStore.clinicalNoteOutput.indexOf(headerText) +
          headerText.length +
          2;
        const endIndex = clinicalNoteWriterStore.clinicalNoteOutput.indexOf(
          "\n\n**",
          startIndex
        );
        const textToCopy = clinicalNoteWriterStore.clinicalNoteOutput
          .substring(
            startIndex,
            endIndex === -1
              ? clinicalNoteWriterStore.clinicalNoteOutput.length
              : endIndex
          )
          .trim();

        return (
          <div className="flex items-center justify-between mb-2">
            <p className="font-extrabold text-xl mt-4">{children}</p>
            <CopyToClipboard text={textToCopy}>
              <button className="p-1 bg-gray-200 rounded hover:bg-gray-300">
                <DuplicateIcon className="w-6 h-6" />
              </button>
            </CopyToClipboard>
          </div>
        );
      },
      hr: () => <hr className="my-4" />,
    };

    return (
      <div className="space-y-4 w-1/2 mt-4">
        {loading ? (
          <div className="mt-12">
            <LoadingOverlay />
          </div>
        ) : clinicalNoteWriterStore.clinicalNoteOutput ? (
          <div>
            <div>
              <div className="bg-white p-4 rounded-lg">
                <div className="flex justify-start mb-4">
                  <CopyToClipboard
                    text={clinicalNoteWriterStore.clinicalNoteOutput}
                  >
                    <button className="py-2 px-4 flex bg-indigo-600 text-white font-semibold rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <DuplicateIcon className="w-6 h-6 mr-2" />
                      Copy All
                    </button>
                  </CopyToClipboard>
                </div>
                <ReactMarkdown components={renderers}>
                  {clinicalNoteWriterStore.clinicalNoteOutput}
                </ReactMarkdown>
              </div>
              <button
                type="submit"
                className="w-full mb-12 mt-4 py-2 px-4 border border-indigo-600 text-indigo-600 font-semibold rounded-md shadow-md hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => {
                  clinicalNoteWriterStore.setClinicalNoteOutput("");
                }}
              >
                Start Over
              </button>
            </div>
          </div>
        ) : (
          <div className="space-y-4 mt-8">
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Client Name
                </label>
                <input
                  type="text"
                  name="clientName"
                  value={formData.clientName}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      clientName: e.target.value,
                    });
                  }}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Note Date
                </label>
                <input
                  type="date"
                  name="noteDate"
                  value={formData.noteDate}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      noteDate: e.target.value,
                    });
                  }}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Duration
                </label>
                <input
                  type="text"
                  name="duration"
                  value={formData.duration}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      duration: e.target.value,
                    });
                  }}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <hr />
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Select Note Format
              </label>
              <Select
                className="basic-single mt-2"
                classNamePrefix="select"
                defaultValue={noteFormatOptions[0]}
                name="noteFormat"
                onChange={handleChange}
                options={[...customTemplates, ...noteFormatOptions]}
                menuPosition="absolute"
              />
              <small>
                To create a custom note format, go to{" "}
                <Link to="/my-profile" className="text-blue-500">
                  My Profile &gt; Custom Templates
                </Link>
                .
              </small>
            </div>
            <hr />
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Primary Diagnosis
                </label>
                <Select
                  className="basic-single mt-2"
                  classNamePrefix="select"
                  defaultValue={primaryDiagnosisOptions[0]}
                  name="primaryDiagnosis"
                  onChange={handleChange}
                  options={primaryDiagnosisOptions}
                  menuPosition="absolute"
                  styles={selectStyle}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Secondary Diagnosis
                </label>
                <Select
                  className="basic-single mt-2"
                  classNamePrefix="select"
                  defaultValue={secondaryDiagnosisOptions[0]}
                  name="secondaryDiagnosis"
                  onChange={handleChange}
                  options={secondaryDiagnosisOptions}
                  menuPosition="absolute"
                  styles={selectStyle}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Tertiary Diagnosis
                </label>
                <Select
                  className="basic-single mt-2"
                  classNamePrefix="select"
                  defaultValue={tertiaryDiagnosisOptions[0]}
                  name="tertiaryDiagnosis"
                  onChange={handleChange}
                  options={tertiaryDiagnosisOptions}
                  menuPosition="absolute"
                  styles={selectStyle}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Mood
                </label>
                <Select
                  className="basic-single mt-2"
                  classNamePrefix="select"
                  name="mood"
                  isMulti
                  onChange={handleMultiChange}
                  options={moodOptions}
                  menuPosition="absolute"
                  styles={selectStyle}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Affect
                </label>
                <Select
                  className="basic-single mt-2"
                  classNamePrefix="select"
                  name="affect"
                  isMulti
                  onChange={handleMultiChange}
                  options={affectOptions}
                  menuPosition="absolute"
                  styles={selectStyle}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Physical
                </label>
                <Select
                  className="basic-single mt-2"
                  classNamePrefix="select"
                  name="physical"
                  isMulti
                  onChange={handleMultiChange}
                  options={physicalOptions}
                  menuPosition="absolute"
                  styles={selectStyle}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Behavior
                </label>
                <Select
                  className="basic-single mt-2"
                  classNamePrefix="select"
                  name="behavior"
                  isMulti
                  onChange={handleMultiChange}
                  options={behaviorOptions}
                  menuPosition="absolute"
                  styles={selectStyle}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Speech
                </label>
                <Select
                  className="basic-single mt-2"
                  classNamePrefix="select"
                  name="speech"
                  isMulti
                  onChange={handleMultiChange}
                  options={speechOptions}
                  menuPosition="absolute"
                  styles={selectStyle}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Progression
                </label>
                <Select
                  className="basic-single mt-2"
                  classNamePrefix="select"
                  name="progression"
                  isMulti
                  onChange={handleMultiChange}
                  options={progressionOptions}
                  menuPosition="absolute"
                  styles={selectStyle}
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Session Notes
              </label>
              <textarea
                name="sessionNotes"
                value={formData.sessionNotes}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    sessionNotes: e.target.value,
                  });
                }}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                rows="4"
              ></textarea>
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={handleSubmit}
              >
                Generate
              </button>
            </div>
          </div>
        )}
      </div>
    );
  })
);

export default ClinicalNoteForm;
