export const progressNoteInstructions = `We are providing a transcript of the patient encounter, detailing the patient's statements, your observations, and any interactions during the session. Include information about the patient's mood, behavior, speech patterns, thought processes, and any notable changes from previous sessions.
**Example of Detailed Information to Include in the Transcript:**
**Patient Statements:**
Direct quotes from the patient.
Descriptions of the patient's mood and emotions.
Specific concerns or issues raised by the patient.
Any significant life events mentioned by the patient.
**Observations:**
Patient's appearance and behavior.
Eye contact, body language, and overall demeanor.
Any physical symptoms or signs.
**Interactions:**
Dialogues between the psychiatrist and the patient.
Questions asked by the psychiatrist and the patient's responses.
Any therapeutic techniques or interventions used.
**Example of Patient Encounter Transcript:**
"Patient entered the room with a slouched posture, avoiding eye contact. They appeared disheveled, wearing wrinkled clothes. The patient stated, 'I haven't been able to sleep well for the past week. I feel exhausted all the time.' The psychiatrist asked, 'Have there been any recent changes in your life that might be contributing to this?' The patient responded, 'I lost my job two weeks ago, and it's been really hard on me.' Throughout the session, the patient spoke in a low, monotonous voice, often pausing for long periods before answering questions. The psychiatrist noted a lack of enthusiasm and energy in the patient's responses."
---
**Progress Note:**
**Subjective:**
The patient, [Patient Name], presented for a follow-up session and reported significant sleep disturbances over the past week, describing feeling "exhausted all the time." The patient disclosed a recent job loss, which appears to be a major stressor.
**Objective:**
The patient appeared disheveled, with wrinkled clothes and slouched posture, and exhibited poor eye contact. Speech was low and monotonous, with noticeable pauses. There was a lack of enthusiasm and energy noted in the patient's responses.
**Assessment:**
The patient is experiencing symptoms of depression, likely exacerbated by the recent job loss. The sleep disturbances and fatigue suggest a potential worsening of depressive symptoms. The patient's affect is blunted, and their overall demeanor indicates significant distress.
**Plan:**
Continue with current medication [specify medication], with a potential adjustment if symptoms persist.
Schedule a follow-up appointment in two weeks to monitor progress.
Recommend cognitive behavioral therapy (CBT) to address negative thought patterns related to job loss.
Discuss potential sleep hygiene strategies to improve rest.`;

export const hpAssessmentInstructions = `We are providing a transcript of the History and Physical (H&P) examination, detailing the patient's medical history, presenting complaint, review of systems, physical examination findings, and any pertinent information discussed during the session. Include the following sections in detail: Chief Complaint, History of Present Illness, Past Medical History, Family History, Social History, Review of Systems, Physical Examination, and Assessment and Plan.
**Example of Detailed Information to Include in the Transcript:**
**Chief Complaint (CC):**
The primary reason for the patient's visit, stated in their own words.
**History of Present Illness (HPI):**
Detailed description of the patient's current symptoms.
Onset, duration, location, severity, and any factors that exacerbate or relieve the symptoms.
Any associated symptoms.
**Past Medical History (PMH):**
Previous diagnoses, surgeries, hospitalizations, and treatments.
Any chronic conditions.
**Family History (FH):**
Medical history of immediate family members.
Any hereditary conditions.
**Social History (SH):**
Lifestyle factors, such as smoking, alcohol consumption, drug use, occupation, and living situation.
Any relevant social factors impacting health.
**Review of Systems (ROS):**
A systematic review of symptoms across various body systems (e.g., cardiovascular, respiratory, gastrointestinal).
**Physical Examination (PE):**
Detailed findings from the physical examination.
Vital signs, general appearance, and specific examination findings for each body system.
**Assessment and Plan (A&P):**
Summary of the key findings.
Differential diagnosis and the plan for further investigation and management.
**Example of H&P Examination Transcript:**
**Chief Complaint:** "I've been having severe headaches for the past two weeks."
**History of Present Illness:**
The patient reports the onset of severe, throbbing headaches two weeks ago. The headaches are located primarily in the frontal region and are rated 8/10 in intensity. They occur daily, typically in the late afternoon, and last for several hours. The patient notes that the headaches are worsened by bright lights and loud noises but are somewhat relieved by lying down in a dark, quiet room. There are no associated visual disturbances, nausea, or vomiting.
**Past Medical History:**
Hypertension, diagnosed 5 years ago, currently managed with lisinopril.
No history of surgeries or hospitalizations.
No known allergies.
**Family History:**
Mother has a history of migraines.
Father has hypertension and type 2 diabetes.
**Social History:**
Non-smoker, occasional alcohol use (1-2 drinks per week).
Works as a software developer.
Lives with spouse and two children.
No illicit drug use.
**Review of Systems:**
General: No weight loss or fever.
Cardiovascular: No chest pain or palpitations.
Respiratory: No shortness of breath or cough.
Gastrointestinal: No abdominal pain, nausea, or vomiting.
Neurological: Headaches as described, no dizziness, seizures, or weakness.
Other systems reviewed and negative.
**Physical Examination:**
Vital Signs: BP 130/85, HR 75, RR 16, Temp 98.6°F.
General: Alert and oriented, no acute distress.
HEENT: PERRLA, no nasal congestion, oropharynx clear.
Cardiovascular: Regular rate and rhythm, no murmurs or gallops.
Respiratory: Clear to auscultation bilaterally, no wheezes or crackles.
Neurological: Cranial nerves II-XII intact, strength 5/5 in all extremities, DTRs 2+ and symmetric.
**Assessment and Plan:**
Assessment: Likely tension headaches, rule out secondary causes.
Plan:
Obtain MRI of the brain to rule out any structural abnormalities.
Prescribe ibuprofen 400 mg as needed for headache relief.
Recommend lifestyle modifications, including regular sleep schedule and stress management techniques.
Follow up in two weeks to assess the response to treatment and review MRI results.`;
