import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { getClinicalNotesCustomTemplates } from "../core/ClinicalNoteWriter/api.service";
import moment from "moment";
import { useHistory } from "react-router-dom";

const CustomTemplatePage = inject(
  "store",
  "clinicalNoteWriterStore"
)(
  observer(({ store, clinicalNoteWriterStore }) => {
    const history = useHistory();

    useEffect(() => {
      getClinicalNotesCustomTemplates().then((res) => {
        clinicalNoteWriterStore.setCustomTemplates(res.data?.templates ?? []);
      });
    }, []);

    return (
      <div>
        <Helmet>
          <title>Custom Templates - NavixAI</title>
        </Helmet>

        <div className="w-full">
          {clinicalNoteWriterStore.customTemplates.length === 0 ? (
            <div className="flex flex-col justify-center items-center h-full">
              <p className="text-gray-500 mb-4">
                No custom templates found. Create your first template.
              </p>
              <button
                className="bg-purple-500 text-white px-4 py-2 rounded"
                onClick={() => {
                  clinicalNoteWriterStore.setSelectedCustomTemplate(null);
                  history.push("/my-profile/custom-templates/edit");
                }}
              >
                Create Template
              </button>
            </div>
          ) : (
            <>
              <div className="mb-4">
                <h1 className={`text-2xl font-bold mb-4`}>
                  Manage your Custom Templates
                </h1>
                <div className="flex flex-row">
                  <p className="text-gray-500">
                    Click on a template to edit or delete or,
                  </p>
                  <button
                    className="bg-purple-500 text-white ml-2 px-4 rounded"
                    onClick={() => {
                      clinicalNoteWriterStore.setSelectedCustomTemplate(null);
                      history.push("/my-profile/custom-templates/edit");
                    }}
                  >
                    Create a Template
                  </button>
                </div>
              </div>
              {clinicalNoteWriterStore.customTemplates.map((entry, index) => (
                <div
                  key={index}
                  className="bg-white p-3 mb-2 rounded shadow cursor-pointer hover:bg-purple-100"
                  onClick={() => {
                    clinicalNoteWriterStore.setSelectedCustomTemplate(entry);
                    history.push("/my-profile/custom-templates/edit");
                  }}
                >
                  <p className="font-semibold truncate">{entry.title}</p>
                  <p className="text-sm text-gray-500">
                    Last modified: {moment(entry.updatedAt).fromNow()}
                  </p>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    );
  })
);

export default CustomTemplatePage;
