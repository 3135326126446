import React, { useState } from "react";
import styled from "styled-components";
import { XIcon } from "@heroicons/react/solid";
import CustomTemplateModal from "./CustomTemplateModal";

const ToolModal = ({
  isOpen,
  onClose,
  title,
  options,
  customTemplates,
  selectedFormat,
  onFormatSelect,
  onGenerate,
  refreshCustomTemplates,
  onTemplateCreated,
  clearUploadedFile,
}) => {
  const [showCustomTemplateForm, setShowCustomTemplateForm] = useState(false);

  if (!isOpen) return null;

  const handleGenerate = (format) => {
    onGenerate(format);
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (!showCustomTemplateForm) {
      onClose();
    }
  };

  const hasCustomTemplates = customTemplates && customTemplates.length > 0;

  const handleCustomTemplateModalClose = async () => {
    setShowCustomTemplateForm(false);
    if (refreshCustomTemplates) {
      await refreshCustomTemplates();
    }
  };

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <XIcon className="icon" />
        </CloseButton>
        <ModalTitle className="text-xl font-bold mb-2">{title}</ModalTitle>
        <OptionContainer>
          {options.map((option) => (
            <OptionButton
              key={option}
              onClick={() => onFormatSelect(option)}
              selected={selectedFormat === option}
            >
              {option}
            </OptionButton>
          ))}
        </OptionContainer>
        <Separator>
          <span>OR</span>
        </Separator>
        {hasCustomTemplates ? (
          <CustomTemplateDropdown
            value={selectedFormat}
            onChange={(e) => onFormatSelect(e.target.value)}
          >
            <option value="">Select Custom Template to Use</option>
            {customTemplates.map((template) => (
              <option key={template._id} value={template._id}>
                {template.title}
              </option>
            ))}
          </CustomTemplateDropdown>
        ) : (
          <CreateCustomTemplateText
            onClick={() => setShowCustomTemplateForm(true)}
          >
            Create your custom template here!
          </CreateCustomTemplateText>
        )}
        <ButtonContainer>
          <GenerateButton
            onClick={() => handleGenerate(selectedFormat)}
            disabled={!selectedFormat}
          >
            Generate
          </GenerateButton>
        </ButtonContainer>
      </ModalContent>
      {showCustomTemplateForm && (
        <CustomTemplateModal
          onClose={handleCustomTemplateModalClose}
          onTemplateCreated={onTemplateCreated}
          clearUploadedFile={clearUploadedFile}
        />
      )}
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
  }

  span {
    padding: 0 10px;
    color: #666;
    font-weight: bold;
  }
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const OptionButton = styled.button`
  background-color: ${(props) => (props.selected ? "#d1c4e9" : "#f0f0f0")};
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  border-radius: 50%;

  .icon {
    width: 20px;
    height: 20px;
    color: #666;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const GenerateButton = styled.button`
  background-color: #6200ea;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #3700b3;
  }

  &:disabled {
    background-color: #bdbdbd;
    cursor: not-allowed;
  }
`;

const CustomTemplateDropdown = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
`;

const CreateCustomTemplateText = styled.p`
  text-align: center;
  margin: 10px 0;
  font-style: italic;
  color: #6200ea;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;

export default ToolModal;
