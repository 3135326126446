import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import ClinicalSidebar from "./components/ClinicalSidebar";
import ClinicalNoteForm from "./components/ClinicalNoteForm";
import { loadClinicalNotesHistories } from "./api.service";

const ClinicalNoteWriter = inject(
  "store",
  "clinicalNoteWriterStore"
)(
  observer(({ clinicalNoteWriterStore }) => {
    useEffect(() => {
      loadClinicalNotesHistories().then((res) => {
        clinicalNoteWriterStore.setHistories(res.data?.result ?? []);
      });
    }, []);

    return (
      <div>
        <Helmet>
          <title>Clinical Note Writer - NavixAI</title>
        </Helmet>

        <div className="flex min-h-screen h-screen flex-row">
          <div className="w-64 h-full">
            <ClinicalSidebar
              histories={clinicalNoteWriterStore.histories}
              onNewEncounter={() => {
                clinicalNoteWriterStore.setClinicalNoteOutput("");
              }}
              onSelectHistory={(history) => {
                clinicalNoteWriterStore.setClinicalNoteOutput(history.output);
              }}
            />
          </div>
          <div className="flex-grow h-full">
            <div className="w-full flex flex-col justify-center items-center">
              <h1 className="text-2xl font-bold mt-4">Clinical Note Writer</h1>
              <ClinicalNoteForm
                clinicalNoteWriterStore={clinicalNoteWriterStore}
              />
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default ClinicalNoteWriter;
