import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Select from "react-select";

const questionOptions = [
  {
    label: "Chief Complaint",
    value:
      "What is the patient's primary reason for seeking treatment during this session?",
  },
  {
    label: "Treatment Plan Builder",
    value:
      "Generate a comprehensive treatment plan tailored to address substance use and co-occurring mental health challenges.",
  },
  {
    label: "Assignments and Homework",
    value:
      "What assignments or homework have been given to the patient between this session and the next?",
  },
  {
    label: "Progress Assessment",
    value:
      "How would you summarize the patient's progress since the last session?",
  },
  {
    label: "Symptoms and Behaviors",
    value:
      "Can you provide details on any specific symptoms or behaviors exhibited by the patient during today's session?",
  },
  {
    label: "Risk Assessment",
    value:
      "Did the session reveal any new information or concerns related to the patient's safety or risk factors?",
  },
  {
    label: "Collaborative Care",
    value:
      "Have there been any collaborative efforts or consultations with other healthcare professionals regarding the patient's care?",
  },
  {
    label: "Patient Goals",
    value:
      "Are there updates or revisions to the patient's short-term and long-term goals?",
  },
  {
    label: "Therapeutic Interventions",
    value:
      "What therapeutic interventions were employed during the session, and how did the patient respond?",
  },
  {
    label: "Follow-up Plans",
    value:
      "What are the specific follow-up plans and recommendations for the patient before the next session?",
  },
  {
    label: "Clinical Diagnosis Impression",
    value:
      "Based on today's session, what is your impression of the patient continuing to meet ASAM criteria for their current level of care (if applicable) and/or DSM criteria for any mental health diagnosis (if applicable)?",
  },
  {
    label: "Cravings Assessment",
    value:
      "What is your impression of the patient's cravings (if applicable) during or following today's session?",
  },
  {
    label: "Medication adherence",
    value:
      "If discussed, have there been any challenges or improvements in the patient's adherence to prescribed medications since the last session?",
  },
  {
    label: "Social Support",
    value:
      "What is the current status of the patient's social support network, and have there been any changes in their relationships or support systems?",
  },
  {
    label: "Coping Strategies",
    value:
      "Discuss any new or existing coping strategies that the patient is utilizing, and assess their effectiveness.",
  },
  {
    label: "Motivation for Change",
    value:
      "Explore the patient's motivation for change and any shifts in their commitment to the treatment plan.",
  },
  {
    label: "Life Stressors",
    value:
      "Identify and discuss any significant life stressors or changes that may impact the patient's overall well-being.",
  },
  {
    label: "Feedback on Therapy",
    value:
      "Ask for the patient's feedback on the therapeutic process and if there are any adjustments they would like to suggest.",
  },
  {
    label: "Cultural Considerations",
    value:
      "Consider any cultural factors that may be influencing the patient's experience or response to treatment.",
  },
  {
    label: "Self-Care Practices",
    value:
      "Inquire about the patient's self-care practices and whether there have been any changes in their self-care routine.",
  },
  {
    label: "Future Planning",
    value:
      "Discuss any future plans or goals the patient has and how these align with their treatment objectives.",
  },
];

const AskQuestionModal = ({ isOpen, onClose, onConfirm }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent ref={modalRef}>
        <h2 className="text-xl font-bold mb-2">Ask a Question</h2>
        <Select
          options={questionOptions}
          value={selectedQuestion}
          onChange={(value) => {
            setSelectedQuestion(value);
          }}
          placeholder="Select a question..."
        />
        {selectedQuestion && (
          <QuestionDisplay>{selectedQuestion.value}</QuestionDisplay>
        )}
        <ButtonContainer>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            primary
            onClick={() => {
              onConfirm(selectedQuestion);
            }}
            disabled={!selectedQuestion}
          >
            Generate
          </Button>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 8px 16px;
  margin-left: 10px;
  border: none;
  border-radius: 20px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) =>
    props.primary ? (props.disabled ? "#a78bda" : "#4c1d95") : "#f3f4f6"};
  color: ${(props) => (props.primary ? "white" : "black")};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  transition:
    background-color 0.3s,
    opacity 0.3s;
  &:hover {
    background-color: ${(props) =>
      props.primary ? (props.disabled ? "#a78bda" : "#3700b3") : "#e5e7eb"};
  }
`;

const QuestionDisplay = styled.div`
  margin-top: 15px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 0.9em;
  color: #333;
  max-height: 100px;
  overflow-y: auto;
`;

export default AskQuestionModal;
