import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

export function Pagination({
  items,
  total,
  perPage,
  currentPage,
  onPageChange,
}) {
  if (
    isNaN(currentPage) ||
    currentPage == "" ||
    (currentPage && currentPage < 1)
  ) {
    throw new Error("Invalid page number");
  } else {
    currentPage ??= 1;
    currentPage = parseInt(currentPage);
  }

  const count = items.length;
  const firstItem = count > 0 ? (currentPage - 1) * perPage + 1 : null;
  const lastItem = count > 0 ? firstItem + count - 1 : null;
  const lastPage = Math.max(parseInt(Math.ceil(total / perPage)), 1);
  const onFirstPage = currentPage == 1;
  const hasMorePages = currentPage < lastPage;

  const range = (start, end, step = 1) => {
    const length = (end - start) / step + 1;
    return Array.from({ length }, (_, value) => start + value * step);
  };

  const getPageRange = (start, end) => {
    return range(start, end).map((value) => ({
      page: value,
    }));
  };

  const getLeft = () => {
    return getPageRange(1, 1);
  };

  const getRight = () => {
    return getPageRange(lastPage, lastPage);
  };

  const pageLinks = () => {
    const onEachSide = 2;

    if (lastPage < onEachSide * 2 + 8) {
      return {
        left: getPageRange(1, lastPage),
        middle: null,
        right: null,
      };
    }

    const window = onEachSide + 2;

    if (currentPage <= window) {
      return {
        left: getPageRange(1, window + onEachSide),
        middle: null,
        right: getRight(),
      };
    } else if (currentPage > lastPage - window) {
      return {
        left: getLeft(),
        middle: null,
        right: getPageRange(lastPage - (window + (onEachSide - 1)), lastPage),
      };
    }

    return {
      left: getLeft(),
      middle: getPageRange(currentPage - onEachSide, currentPage + onEachSide),
      right: getRight(),
    };
  };

  const elements = () => {
    const links = pageLinks();

    return [
      links.left,
      Array.isArray(links.middle) ? "..." : null,
      links.middle,
      Array.isArray(links.right) ? "..." : null,
      links.right,
    ].filter((link) => link !== null);
  };

  const handlePageClick = (page) => {
    if (page >= 1 && page <= lastPage) {
      onPageChange(page);
    }
  };

  return (
    <nav className="flex items-center justify-between p-8">
      <div className="flex-1 flex items-center justify-between">
        {total > 1 && (
          <div>
            <p className="text-sm text-gray-600">
              Showing {firstItem} to {lastItem} of {total} results
            </p>
          </div>
        )}

        {lastPage > 1 && (
          <div className="flex gap-1">
            <button
              onClick={() => handlePageClick(currentPage - 1)}
              disabled={onFirstPage}
              className="p-2 text-gray-600 bg-white rounded hover:bg-gray-50 disabled:opacity-50"
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </button>

            {elements().map((element, index) => {
              return typeof element === "string" ? (
                <span
                  key={index}
                  className="px-4 py-2 text-sm text-gray-600 bg-white"
                >
                  {element}
                </span>
              ) : (
                Array.isArray(element) &&
                  element.map(({ page }) => (
                    <button
                      key={page}
                      onClick={() => handlePageClick(page)}
                      className={`px-4 py-2 text-sm rounded ${
                        page === currentPage
                          ? "bg-indigo-600 text-white"
                          : "text-gray-600 hover:bg-gray-50"
                      }`}
                    >
                      {page}
                    </button>
                  ))
              );
            })}

            <button
              onClick={() => handlePageClick(currentPage + 1)}
              disabled={!hasMorePages}
              className="p-2 text-gray-600 bg-white rounded hover:bg-gray-50 disabled:opacity-50"
            >
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Pagination;
