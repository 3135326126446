import {
  ArchiveIcon,
  InformationCircleIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import Modal from "../../../../components/Modal";

const ICON_COMPONENTS = {
  remove: TrashIcon,
  archive: ArchiveIcon,
  unarchive: InformationCircleIcon,
};

const CONFIRM_BUTTON_CLASS = {
  remove:
    "border-red-200 hover:bg-red-800 focus:ring-red-300 dark:focus:ring-red-800",
  default:
    "border-blue-200 hover:bg-blue-800 focus:ring-blue-300 dark:focus:ring-blue-800",
};

const HistoryConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  handleAction,
  id,
  modalInfo,
}) => {
  const { title, description } = modalInfo;
  const lowCaseTitle = title.toLowerCase();

  const IconComponent = ICON_COMPONENTS[lowCaseTitle] || TrashIcon;
  const confirmButtonClass =
    CONFIRM_BUTTON_CLASS[lowCaseTitle] || CONFIRM_BUTTON_CLASS.default;

  return (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <div className="text-center w-96">
        <IconComponent className="w-16 mx-auto text-red-500" />
        <div className="mx-auto my-4 w-96">
          <h3 className="text-lg font-block text-gray-800">
            {`Confirm ${title}`}
          </h3>
          <p className="text-sm text-gray-500">
            {`Are you sure you want to ${description} this history?`}
          </p>
        </div>
        <div className="flex gap-4">
          <button
            type="button"
            className={`w-full hover:text-white text-gray-500 border focus:ring-4 focus:outline-none rounded-lg text-sm px-5 py-2.5 text-center ${confirmButtonClass}`}
            onClick={() => handleAction(id, title)}
          >
            Yes, I'm sure
          </button>
          <button
            type="button"
            className="w-full text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
            onClick={() => setIsModalOpen(false)}
          >
            No, cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default HistoryConfirmationModal;
