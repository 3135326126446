import { XIcon, RefreshIcon } from "@heroicons/react/outline";

export const AddUserModal = ({
  newUser,
  userPlans,
  plans,
  isUpdating,
  handleSave,
  handleCancel,
  handleInputChange,
  errorMessage,
}) => {
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-600 bg-opacity-50">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="relative bg-white rounded-lg shadow-xl w-full max-w-md p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900">Add New User</h3>
            <button
              onClick={handleCancel}
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-6 w-6" />
            </button>
          </div>

          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  First Name
                </label>
                <input
                  type="text"
                  value={newUser.fname || ""}
                  onChange={(e) => handleInputChange("fname", e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500"
                  placeholder="John"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <input
                  type="text"
                  value={newUser.lname}
                  onChange={(e) => handleInputChange("lname", e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500"
                  placeholder="Doe"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                value={newUser.email || ""}
                onChange={(e) => handleInputChange("email", e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500"
                placeholder="john@example.com"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                type="text"
                value={newUser.password || ""}
                onChange={(e) => handleInputChange("password", e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500"
                placeholder="Enter password"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Plan
              </label>
              <select
                value={newUser.plan || "Trial"}
                onChange={(e) => handleInputChange("plan", e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 rounded-md"
              >
                <option key="trial" value="trial">
                  Trial
                </option>
                {userPlans
                  .filter((plan) => plan !== "Trial")
                  .map((plan) => (
                    <option
                      key={`userplan-${plan.toLowerCase()}`}
                      value={plan.toLowerCase()}
                    >
                      {plan}
                    </option>
                  ))}
                {plans.map((plan) => (
                  <option
                    key={`plan-${plan.toLowerCase()}`}
                    value={plan.toLowerCase()}
                  >
                    {plan}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Credits
              </label>
              <input
                type="number"
                value={newUser.credits || 250}
                onChange={(e) =>
                  handleInputChange("credits", parseInt(e.target.value) || 0)
                }
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500"
                min="0"
                placeholder="250"
              />
            </div>
          </div>

          {errorMessage && (
            <div className="mt-4 text-sm text-red-600">{errorMessage}</div>
          )}

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={handleCancel}
              disabled={isUpdating}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={isUpdating}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              {isUpdating ? (
                <RefreshIcon className="h-5 w-5 animate-spin" />
              ) : (
                "Create User"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
