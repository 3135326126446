import { inject, observer } from "mobx-react";
import { useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { createSummary } from "../api.service";
import GenerateButton from "./GenerateButton";
import React from "react";

const FORMAT = [
  { value: "narrative", label: "Narrative" },
  { value: "bullet-point", label: "Bullet Point" },
  { value: "chronological", label: "Chronological" },
  { value: "problem-oriented", label: "Problem-Oriented" },
  { value: "problem-solution-format", label: "Problem-Solution Format" },
  { value: "outline-format", label: "Outline Format" },
];

const LENGTH = [
  { value: "short", label: "Short" },
  { value: "custom-length", label: "Custom-Length" },
  { value: "expanded-detail", label: "Expanded Detail" },
  { value: "key-points-only", label: "Key Ponts Only" },
  { value: "moderate-length", label: "Moderate Length" },
];

const STYLE = [
  { value: "informal", label: "Informal" },
  { value: "formal-business", label: "Formal Business" },
  { value: "casual-conversation", label: "Casual Conversation" },
  { value: "educational", label: "Educational" },
  {
    value: "narrative-collaborative-notes",
    label: "Narrative-Collaborative Notes",
  },
  {
    value: "problem-oriented-medical-record",
    label: "Problem-Oriented Medical Record (POMR)",
  },
];

const CreateSummary = inject(
  "store",
  "navixScribeStore"
)(
  observer(({ store, navixScribeStore }) => {
    const [summaryInstructions, setSummaryInstructions] = useState("");
    const [payload, setPayload] = useState({
      format: { value: "narrative", label: "Narrative" },
      length: { value: "short", label: "Short" },
      style: { value: "informal", label: "Informal" },
    });

    const handleGenerateAnswer = async () => {
      if (
        !navixScribeStore.inputActiveRecord.output &&
        !navixScribeStore.inputActiveRecord.outputs
      ) {
        return toast.error("Please select input first!");
      }

      const input = navixScribeStore.inputActiveRecord.output
        ? navixScribeStore.inputActiveRecord.output
        : navixScribeStore.inputActiveRecord.outputs.toString();

      const { data } = await createSummary(input, {
        ...{
          format: payload.format.value,
          length: payload.length.value,
          style: payload.style.value,
        },
        summary: summaryInstructions || "",
      });

      navixScribeStore.setOutputActiveRecord(data.history);
      toast.success("Successfully created a summary.");
      navixScribeStore.setTriggerSection("Summary");
    };

    const handleChangeSelect = (e, name) => {
      setPayload((prev) => ({ ...prev, [name]: e }));
    };

    return (
      <>
        <div className="py-2">
          <div className="divide-ngray flex flex-col divide-y">
            <div className="grid grid-cols-3 items-center pb-3">
              <label htmlFor="format">Select Format</label>
              <Select
                isSearchable
                className="col-span-2"
                id="format"
                defaultValue={FORMAT[0]}
                onChange={(e) => handleChangeSelect(e, "format")}
                options={FORMAT}
              />
            </div>
            <div className="grid grid-cols-3 items-center py-2">
              <label htmlFor="length">Select Length</label>
              <Select
                isSearchable
                className={`col-span-${
                  payload.length.label === "Custom-Length" ? 1 : 2
                }`}
                id="length"
                defaultValue={LENGTH[0]}
                onChange={(e) => handleChangeSelect(e, "length")}
                options={LENGTH}
              />
              {payload.length.label === "Custom-Length" && (
                <input
                  className="h-9 rounded p-1 ml-1 text-gray-600"
                  type="number"
                  min="1"
                  placeholder="Enter your word count"
                  onChange={(e) =>
                    setPayload((prev) => ({
                      ...prev,
                      length: {
                        value: e.target.value,
                        label: "Custom-Length",
                      },
                    }))
                  }
                />
              )}
            </div>
            <div className="grid grid-cols-3 items-center py-2">
              <label htmlFor="style">Select Style</label>
              <Select
                isSearchable
                className="col-span-2"
                id="style"
                defaultValue={STYLE[0]}
                onChange={(e) => handleChangeSelect(e, "style")}
                options={STYLE}
              />
            </div>

            <div className="grid py-3">
              <label htmlFor="summaryInstructions">Summary Instructions</label>
              <textarea
                name="summaryInstructions"
                id="summaryInstructions"
                className="rounded p-1"
                rows={4}
                cols={4}
                value={summaryInstructions}
                onChange={(e) => setSummaryInstructions(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>

        <GenerateButton generateFn={handleGenerateAnswer} />
      </>
    );
  })
);

export default CreateSummary;
