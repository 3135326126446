import React from "react";
import { XIcon, CheckIcon } from "@heroicons/react/solid";

const PricingModal = ({
  isOpen,
  onClose,
  onConfirm,
  selectedPlan,
  onPlanChange,
  checkoutUrl,
}) => {
  if (!isOpen) return null;

  const plans = {
    entry: {
      title: "Entry",
      price: "$14.99",
      features: [
        "Note writer + Note rewriter",
        "Summarizer",
        "Blogs and Articles",
        "Diagnostic Code",
      ],
      disabled: ["NavixScribe"],
      badge: "AI-Powered Features",
    },
    pro: {
      title: "Professional",
      price: "$58.99",
      features: [
        "Unlimited Transcription",
        "NavixScribe",
        "Access to all tools",
        "Early access to beta tools",
        "Everything in entry plan",
      ],
      badge: "AI-Powered Features",
    },
  };

  const FeatureIcon = ({ type }) => {
    const iconStyles = {
      check: "bg-[#44515A]",
      disabled: "bg-[#95000F]",
      pro: "bg-[#4336AB]",
    };

    return (
      <div
        className={`rounded-full p-0.5 ${iconStyles[type]} w-4 h-4 flex items-center justify-center flex-shrink-0`}
      >
        {type === "disabled" ? (
          <XIcon className="h-3 w-3 text-white" />
        ) : (
          <CheckIcon className="h-3 w-3 text-white" />
        )}
      </div>
    );
  };

  const BadgeComponent = ({ text }) => (
    <div className="absolute right-2.5 top-2.5">
      <div className="inline-block px-4 py-1 rounded-[20px] text-sm bg-white border-2 border-[#4336AB] text-[#4336AB] font-semibold">
        {text}
      </div>
    </div>
  );

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div
        className="flex min-h-screen items-center justify-center p-4"
        onClick={onClose}
      >
        {/* Semi-transparent backdrop */}
        <div className="fixed inset-0 bg-black bg-opacity-20 transition-opacity" />

        {/* Modal container */}
        <div
          className="relative w-full max-w-5xl"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Modal content */}
          <div className="bg-white p-10 px-16 rounded-xl shadow-xl">
            <h2 className="text-2xl font-bold text-center mb-12">
              Trial Period Ended
            </h2>

            <div className="flex flex-col md:flex-row gap-8 justify-center items-stretch">
              {/* Entry Plan */}
              <div className="relative flex-1 max-w-md">
                {/* Tilted background */}
                <div
                  className="absolute -top-1 left-2 w-[95%] h-[101%] rounded-xl transform skew-y-6"
                  style={{
                    background:
                      "linear-gradient(122.71deg, #C19DFE 32.81%, #422D65 110.41%)",
                    opacity: "0.4",
                    zIndex: 0,
                  }}
                />

                {/* Card content */}
                <div className="relative bg-white rounded-xl p-4 border-2 border-[#B4B9BD] shadow-lg z-10 h-full flex flex-col">
                  <BadgeComponent text={plans.entry.badge} />
                  <div className="mb-8">
                    <h3 className="text-2xl font-bold">{plans.entry.title}</h3>
                    <div className="text-3xl font-bold mt-2">
                      {plans.entry.price}
                      <span className="text-gray-500 text-lg">/month</span>
                    </div>
                  </div>

                  <ul className="space-y-2 flex-grow">
                    {plans.entry.features.map((feature, index) => (
                      <li key={index} className="flex items-center gap-3">
                        <FeatureIcon type="check" />
                        <span>{feature}</span>
                      </li>
                    ))}
                    {plans.entry.disabled.map((feature, index) => (
                      <li
                        key={`disabled-${index}`}
                        className="flex items-center gap-3"
                      >
                        <FeatureIcon type="disabled" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>

                  <button
                    onClick={() =>
                      onPlanChange({
                        target: {
                          value: "entry",
                        },
                      })
                    }
                    className="w-full mt-8 bg-[#4336AB] text-white rounded-lg py-3 font-medium hover:opacity-90 transition-colors"
                  >
                    Subscribe
                  </button>
                </div>
              </div>

              {/* Pro Plan */}
              <div className="relative flex-1 max-w-md">
                {/* Tilted background */}
                <div
                  className="absolute -top-1 left-2 w-[95%] h-[101%] rounded-xl transform skew-y-6"
                  style={{
                    background:
                      "linear-gradient(122.71deg, #C19DFE 32.81%, #422D65 110.41%)",
                    opacity: "0.4",
                    zIndex: 0,
                  }}
                />
                <div className="relative bg-white rounded-xl p-4 border-2 border-[#B4B9BD] shadow-lg z-10 h-full flex flex-col">
                  <BadgeComponent text={plans.pro.badge} />
                  <div className="mb-8">
                    <h3 className="text-2xl font-bold">{plans.pro.title}</h3>
                    <div className="text-3xl font-bold mt-2">
                      {plans.pro.price}
                      <span className="text-gray-500 text-lg">/month</span>
                    </div>
                  </div>

                  <ul className="space-y-2 flex-grow">
                    {plans.pro.features.slice(0, -1).map((feature, index) => (
                      <li key={index} className="flex items-center gap-3">
                        <FeatureIcon type="check" />
                        <span>{feature}</span>
                      </li>
                    ))}
                    <li className="relative py-4">
                      <div className="absolute left-0 right-0 top-1/2 border-t border-gray-200"></div>
                      <p className="text-center text-sm text-gray-500 bg-white relative z-10 w-12 mx-auto">
                        AND
                      </p>
                    </li>
                    <li className="flex items-center gap-3">
                      <FeatureIcon type="pro" />
                      <span>
                        {plans.pro.features[plans.pro.features.length - 1]}
                      </span>
                    </li>
                  </ul>

                  <button
                    onClick={() =>
                      onPlanChange({
                        target: {
                          value: "pro",
                        },
                      })
                    }
                    className="w-full mt-8 bg-[#4336AB] text-white rounded-lg py-3 font-medium hover:opacity-90 transition-colors"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingModal;
