import Select, { components } from "react-select";
import { inject, observer } from "mobx-react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useState, useEffect } from "react";
import { fetchPersonas } from "../api.service";

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div>{props.data.name}</div>
      <div className={`text-xs text-gray-${props.isSelected ? "50" : "500"}`}>
        {props.data.description}
      </div>
    </components.Option>
  );
};

export const Persona = inject("navixScribeStore")(
  observer(({ navixScribeStore }) => {
    const [open, setOpen] = useState(true);
    const [options, setOptions] = useState([]);

    const handleClick = () => setOpen((prev) => !prev);
    const iconClass = `rotate-${open ? "0" : "180"}`;
    const descClass = open ? "pb-2 max-h-4/5" : "overflow-hidden max-h-0";
    const contentClass = open ? "py-2 max-h-4/5" : "overflow-hidden max-h-0";
    const isActive = open ? "divide-y divide-ngray" : "";

    useEffect(() => {
      fetch();
      const savedPersona = navixScribeStore.selectedPersona;
      if (savedPersona) {
        navixScribeStore.setSelectedPersona(savedPersona);
      }
    }, []);

    async function fetch() {
      const personas = await fetchPersonas();
      setOptions(
        personas.personas.map((p) => ({ ...p, label: p.name, value: p._id }))
      );
    }

    const handleChange = (selectedOption) => {
      navixScribeStore.setSelectedPersona(selectedOption);
      localStorage.setItem("selectedPersona", JSON.stringify(selectedOption)); // Save to local storage
    };

    return (
      <div
        className={`border hidden sm:block rounded-lg border-gray-400 bg-white p-4 mb-1 ${isActive}`}
      >
        <div className="flex flex-col cursor-pointer" onClick={handleClick}>
          <div className="flex justify-between items-center">
            <h1 className="text-xl text-navix font-semibold capitalize truncate">
              Settings
            </h1>
            <ChevronDownIcon
              className={`w-5 h-5 transition-all duration-500 ${iconClass}`}
            />
          </div>
          <p
            className={`text-base truncate transition-[max-height] ease-in duration-500 ${descClass}`}
          >
            {/* Description */}
          </p>
        </div>
        <div
          className={`transition-[max-height] ease-in duration-500 ${contentClass}`}
        >
          <Select
            components={{ Option }}
            placeholder="Select Persona"
            isClearable={true}
            isSearchable={true}
            name="persona"
            options={options}
            value={navixScribeStore.selectedPersona}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  })
);
