import React from "react";
import { Helmet } from "react-helmet";
import { MicrophoneIcon } from "@heroicons/react/solid";

const DownloadDesktop = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-40 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>Download Navix Desktop App</title>
      </Helmet>

      <div className="max-w-md mx-auto text-center">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">
          Download Our Desktop App!
        </h1>

        <div className="bg-white rounded-xl shadow-lg p-16 mb-8 border-2 border-indigo-450">
          <div className="flex justify-center mb-4">
            <div className="bg-indigo-100 p-3 rounded-full">
              <MicrophoneIcon className="h-8 w-8 text-indigo-600" />
            </div>
          </div>

          <h2 className="text-3xl font-semibold text-gray-900 mb-2">
            NavixAI Desktop App
          </h2>

          <p className="text-gray-600 mb-6">Highest quality voice record</p>

          <button
            onClick={() => {
              console.log("download...");
            }}
            className="w-full border-2 border-indigo-600 text-indigo-600 rounded-full py-2 px-4 hover:bg-indigo-600 hover:text-white transition-colors duration-200"
          >
            Download Here
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadDesktop;
