import { Component, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { observable, makeObservable } from "mobx";
import { observer, inject } from "mobx-react";
import {
  DocumentDownloadIcon,
  XIcon,
  RefreshIcon,
  DotsVerticalIcon,
  SearchIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  MenuIcon,
} from "@heroicons/react/outline";
import FileDownload from "js-file-download";
import toast from "react-hot-toast";
import Body from "../components/Body";
import Pagination from "../components/Pagination";
import { debounce } from "lodash";
import { runInAction } from "mobx";
import { AddUserModal } from "../components/AddUserModal";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

@inject("store")
@observer
class AdminTool extends Component {
  @observable tool = {};
  @observable perPage = null;
  @observable currentPage = null;
  @observable fields = [];
  @observable total = 0;
  @observable users = [];
  @observable filter = {};
  @observable selectedPlan = null;
  @observable search = null;
  @observable sortBy = null;
  @observable orderBy = null;
  @observable showDropdownMenu = false;
  @observable dropdownMenuId = null;
  @observable isUpdating = false;
  @observable userId = null;
  @observable credits = 0;
  @observable plan = null;
  @observable plans = [
    "Trial",
    "Entry",
    "Pro",
    "VIP",
    "Hub",
    "Employee",
  ].sort();
  @observable userPlans = ["Entry", "Pro"];
  @observable showAddCreditsModal = false;
  @observable showModifyPlanModal = false;
  @observable showChangePasswordModal = false;
  @observable newPassword = "";
  @observable errorMessage = "";
  @observable showAddUserModal = false;
  @observable newUser = {
    fname: "",
    lname: "",
    email: "",
    password: "",
    plan: "Trial",
    credits: 250,
    gateway: "stripe",
  };
  @observable autoRenew = true;
  @observable currentPeriodEnd = null;
  @observable status = null;

  constructor(props) {
    super(props);

    makeObservable(this);

    this.tool = this.props.store.getAdminToolByUrl(
      this.props.location.pathname
    );

    if (!this.tool) {
      window.location.href = "/";
    }

    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    this.selectedPlan = searchParams.get("plan");
    this.search = searchParams.get("search");

    this.sortBy = searchParams.get("sort_by");
    this.orderBy = searchParams.get("order_by");
    this.currentPage = searchParams.get("page");

    this.filter = {
      plan: this.selectedPlan,
      search: this.search,
    };

    this.getUsers();

    this.debouncedSearch = debounce(this.performSearch, 300);
  }

  getUsers = async () => {
    try {
      const response = await this.props.store.api.get(this.tool.api, {
        params: {
          filter: JSON.stringify(this.filter),
          sort_by: this.sortBy,
          order_by: this.orderBy,
          page: this.currentPage,
        },
      });

      const data = response.data;

      if (data.success && data.result) {
        runInAction(() => {
          this.fields = data.result.fields || [];
          this.perPage = data.result.per_page || 15;
          this.total = data.result.total || 0;
          this.users = Array.isArray(data.result.users)
            ? data.result.users
            : [];
        });
      } else {
        console.error("Invalid response format:", data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Failed to fetch users");
    }
  };

  exportCsv = async () => {
    try {
      const response = await this.props.store.api.get(this.tool.api, {
        params: {
          type: "export",
          file: "csv",
        },
      });

      const data = response.data;

      if (data) {
        FileDownload(data, "users.csv");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  handleSearch = (event) => {
    event.preventDefault();
    if (this.search && this.search.length >= 3) {
      this.filter.search = this.search;
      this.currentPage = 1;
      this.updateUrl();
      this.getUsers();
    }
  };

  handlePlanChange = (plan) => {
    runInAction(() => {
      this.currentPage = 1;
      this.filter.plan = plan;
      this.selectedPlan = plan;
      this.showDropdownMenu = false;
      this.updateUrl();
      this.getUsers();
    });
  };

  generateFilterUrl = (filter = null, value = null) => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    if (filter && value) {
      searchParams.set(filter, value);
    }

    return url.pathname + "?" + searchParams.toString();
  };

  setShowAddCreditsModal = (value) => {
    this.showAddCreditsModal = value;
  };

  setShowModifyPlanModal = (value) => {
    this.showModifyPlanModal = value;
  };

  setShowChangePasswordModal = (value) => {
    this.showChangePasswordModal = value;
  };

  setErrorMessage = (message) => {
    this.errorMessage = message;
  };

  handleUpdateCredits = async (userId) => {
    this.isUpdating = true;

    try {
      const response = await this.props.store.api.put(
        `${this.tool.api}/${userId}`,
        {
          credits: this.credits,
        }
      );

      const data = response.data;

      if (data.success) {
        this.users = this.users.map((user) => {
          if (user._id === userId) {
            user.credits = data.result.user.credits;
          }

          return user;
        });

        toast.success("Successfully updated credits.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      this.isUpdating = false;
      this.userId = null;
      this.credits = 0;
      this.setShowAddCreditsModal(false);
    }
  };

  handleUpdatePlan = async (userId) => {
    this.isUpdating = true;

    try {
      const response = await this.props.store.api.put(
        `${this.tool.api}/${userId}/plan`,
        {
          plan: this.plan,
          autoRenew: this.autoRenew,
          currentPeriodEnd: this.currentPeriodEnd,
          status: this.status,
        }
      );

      if (response.data.success) {
        this.users = this.users.map((user) => {
          if (user._id === userId) {
            return {
              ...user,
              plan: this.plan,
              status: this.status,
              auto_renewal: this.autoRenew ? "Yes" : "No",
              current_period_end: moment(this.currentPeriodEnd).format(
                "MMM D, YYYY"
              ),
            };
          }
          return user;
        });

        toast.success("Successfully updated plan");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update plan");
    } finally {
      this.isUpdating = false;
      this.setShowModifyPlanModal(false);
      this.resetPlanFields();
    }
  };

  handleChangePassword = async (userId) => {
    if (this.newPassword.length < 6) {
      this.setErrorMessage("Password must be at least 6 characters long");
      return;
    }

    this.isUpdating = true;
    this.setErrorMessage("");

    try {
      const response = await this.props.store.api.put(
        `${this.tool.api}/${userId}/change-password`,
        {
          password: this.newPassword,
        }
      );

      const data = response.data;

      if (data.success) {
        toast.success("Successfully changed password.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      this.isUpdating = false;
      this.userId = null;
      this.newPassword = "";
      this.setShowChangePasswordModal(false);
    }
  };

  performSearch = (searchTerm) => {
    if (searchTerm.length >= 3 || searchTerm.length === 0) {
      runInAction(() => {
        this.currentPage = 1;
        this.filter.search = searchTerm;
        this.updateUrl();
        this.getUsers();
      });
    }
  };

  handleSort = (sortBy) => {
    runInAction(() => {
      if (this.sortBy === sortBy) {
        this.orderBy = this.orderBy === "asc" ? "desc" : "asc";
      } else {
        this.sortBy = sortBy;
        this.orderBy = "asc";
      }

      this.currentPage = 1;
      this.updateUrl();
      this.getUsers();
    });
  };

  updateUrl = () => {
    const searchParams = new URLSearchParams();
    if (this.filter.plan) searchParams.set("plan", this.filter.plan);
    if (this.filter.search) searchParams.set("search", this.filter.search);
    if (this.sortBy) searchParams.set("sort_by", this.sortBy);
    if (this.orderBy) searchParams.set("order_by", this.orderBy);
    if (this.currentPage > 1) searchParams.set("page", this.currentPage);

    this.props.history.replace({
      search: searchParams.toString(),
    });
  };

  handlePageChange = (page) => {
    runInAction(() => {
      this.currentPage = page;
      this.updateUrl();
      this.getUsers();
    });
  };

  handleResetFilters = () => {
    runInAction(() => {
      this.search = "";
      this.selectedPlan = null;
      this.sortBy = null;
      this.orderBy = null;
      this.currentPage = 1;
      this.filter = {
        plan: null,
        search: null,
      };
      this.updateUrl();
      this.getUsers();
    });
  };

  handleAddUser = async () => {
    this.isUpdating = true;
    this.setErrorMessage("");

    try {
      if (
        !this.newUser.email ||
        !this.newUser.password ||
        !this.newUser.fname ||
        !this.newUser.lname
      ) {
        this.setErrorMessage("All fields are required");
        return;
      }

      if (this.newUser.password.length < 6) {
        this.setErrorMessage("Password must be at least 6 characters long");
        return;
      }

      const response = await this.props.store.api.post(
        `${this.tool.api}/create`,
        this.newUser
      );

      if (response.data.success) {
        toast.success("Successfully created new user");
        this.getUsers();
        this.showAddUserModal = false;
        this.newUser = {
          fname: "",
          lname: "",
          email: "",
          password: "",
          plan: "Trial",
          credits: 250,
          gateway: "stripe",
        };
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to create user. Please check your configuration.";
      toast.error(errorMessage);
      this.setErrorMessage(errorMessage);
    } finally {
      this.isUpdating = false;
    }
  };

  resetPlanFields = () => {
    this.userId = null;
    this.plan = null;
    this.status = null;
    this.autoRenew = true;
    this.currentPeriodEnd = null;
  };

  statusClasses = (user) => {
    switch (user.status) {
      case "Active":
        return "bg-green-750 text-neutral-900";
      case "Trialing":
        return "bg-yellow-200 text-neutral-900";
      case "Canceled":
      case "Past Due":
        return "bg-red-300 text-neutral-900";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{`${this.tool.title} - NavixAI`}</title>
        </Helmet>

        <Body className="p-8">
          <div className="flex flex-col space-y-4 p-8">
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <div className="flex items-center gap-2 mb-1">
                  <MenuIcon className="w-5 h-5 text-gray-600" />
                  <h1 className="text-xl font-semibold text-gray-900">Users</h1>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <div className="relative">
                  <button
                    type="button"
                    className="px-4 py-2 w-32 text-sm font-medium text-indigo-600 bg-white border border-indigo-600 rounded-lg hover:bg-gray-50 flex items-center justify-between"
                    onClick={() => {
                      this.showDropdownMenu = !this.showDropdownMenu;
                      this.dropdownMenuId = "filter";
                    }}
                  >
                    {!this.selectedPlan || this.selectedPlan === "all"
                      ? "All Plans"
                      : this.selectedPlan}
                    <ChevronDownIcon className="text-indigo-600 w-4 h-4" />
                  </button>

                  {this.showDropdownMenu &&
                    this.dropdownMenuId === "filter" && (
                      <div>
                        <div
                          className="fixed inset-0 z-10"
                          onClick={() => {
                            this.showDropdownMenu = false;
                          }}
                        ></div>

                        <div className="absolute left-0 z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                          <ul className="py-2 text-sm text-gray-700">
                            {["All plans"]
                              .concat(this.userPlans)
                              .concat(this.plans)
                              .map((plan, index) => (
                                <li key={index}>
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handlePlanChange(
                                        plan === "All plans" ? "all" : plan
                                      );
                                    }}
                                    className="block px-4 py-2 hover:bg-gray-100"
                                  >
                                    {plan}
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    )}
                </div>

                <div className="relative w-80">
                  <input
                    type="text"
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm"
                    placeholder="Search by name or email"
                    value={this.search || ""}
                    onChange={(e) => {
                      runInAction(() => {
                        this.search = e.target.value;
                        this.debouncedSearch(e.target.value);
                      });
                    }}
                  />
                  <SearchIcon className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                </div>

                <button
                  type="button"
                  onClick={this.handleResetFilters}
                  className="px-4 py-2 text-sm font-medium text-indigo-600 bg-white border border-indigo-600 rounded-lg hover:bg-gray-50"
                >
                  Reset Filters
                </button>

                <button
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-indigo-600 bg-white border border-indigo-600 rounded-lg hover:bg-gray-50 inline-flex items-center"
                  onClick={this.exportCsv}
                >
                  <DocumentDownloadIcon className="mr-2 h-4 w-4 text-indigo-600" />{" "}
                  Export
                </button>
              </div>
            </div>

            <div>
              <button
                onClick={() =>
                  runInAction(() => (this.showAddUserModal = true))
                }
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
              >
                Add User
              </button>
            </div>
          </div>

          <div className="px-8">
            <div className="relative overflow-x-auto shadow-lg rounded-lg">
              <table className="w-full text-sm text-left">
                <thead>
                  <tr className="bg-gray-100">
                    <th
                      className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider cursor-pointer"
                      onClick={() => this.handleSort("name")}
                    >
                      <div className="flex items-center">
                        Name
                        {this.renderSortIcon("name")}
                      </div>
                    </th>
                    <th className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider">
                      Email
                    </th>
                    <th className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider">
                      Gateway
                    </th>
                    <th className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider">
                      Plan
                    </th>
                    <th className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider">
                      VALID SUBSCRIPTION
                    </th>
                    <th
                      className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider cursor-pointer"
                      onClick={() => this.handleSort("created")}
                    >
                      <div className="flex items-center">
                        SIGNED UP
                        {this.renderSortIcon("created")}
                      </div>
                    </th>
                    <th
                      className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider cursor-pointer"
                      onClick={() => this.handleSort("trial_end")}
                    >
                      <div className="flex items-center">
                        TRIAL EXPIRATION
                        {this.renderSortIcon("trial_end")}
                      </div>
                    </th>
                    <th
                      className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider cursor-pointer"
                      onClick={() => this.handleSort("current_period_end")}
                    >
                      <div className="flex items-center">
                        SUBSCRIPTION EXPIRATION
                        {this.renderSortIcon("current_period_end")}
                      </div>
                    </th>
                    <th className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider">
                      AUTO-RENEWAL
                    </th>
                    <th
                      className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider cursor-pointer"
                      onClick={() => this.handleSort("creditsUsed")}
                    >
                      <div className="flex items-center">
                        TOKENS USED
                        {this.renderSortIcon("creditsUsed")}
                      </div>
                    </th>
                    <th
                      className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider cursor-pointer"
                      onClick={() => this.handleSort("credits")}
                    >
                      <div className="flex items-center">
                        TOKENS BALANCE
                        {this.renderSortIcon("credits")}
                      </div>
                    </th>
                    <th className="px-4 py-3 text-xs font-semibold text-neutral-900 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.users.map((user) => (
                    <tr key={user._id} className="hover:bg-gray-50">
                      <td className="px-4 py-3 whitespace-nowrap">
                        {user.name || "N/A"}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {user.email || "N/A"}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {user.gateway || ""}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {user.plan || ""}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {user.status && (
                          <span
                            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${this.statusClasses(user)}`}
                          >
                            {user.status.charAt(0).toUpperCase() +
                              user.status.slice(1)}
                          </span>
                        )}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {user.isSubscriptionValid ? "Yes" : "No"}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {moment(user.created).format("MM-DD-YYYY")}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {user.trial_end === "Invalid date"
                          ? "N/A"
                          : moment(user.trial_end).format("MM-DD-YYYY")}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {user.current_period_end === "Invalid date"
                          ? "N/A"
                          : moment(user.current_period_end).format(
                              "MM-DD-YYYY"
                            )}
                      </td>
                      <td className="px-4 py-3 text-center">
                        {user.auto_renewal || "No"}
                      </td>
                      <td className="px-4 py-3 text-center">
                        {user.creditsUsed || 0}
                      </td>
                      <td className="px-4 py-3 text-center">
                        {user.credits || 0}
                      </td>
                      <td className="p-2">
                        <div className="relative">
                          <button
                            type="button"
                            className="inline-flex items-center text-sm font-medium text-center text-gray-900 rounded-lg hover:bg-gray-100"
                            onClick={() => {
                              this.showDropdownMenu = !this.showDropdownMenu;

                              this.dropdownMenuId = user._id;
                            }}
                          >
                            <DotsVerticalIcon className="w-4 h-4" />
                          </button>

                          {this.showDropdownMenu &&
                            this.dropdownMenuId === user._id && (
                              <div>
                                <div
                                  className="fixed inset-0 z-10"
                                  onClick={() => {
                                    this.showDropdownMenu = false;
                                  }}
                                ></div>

                                <div className="absolute right-0 z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                                  <ul className="py-2 text-sm text-gray-700">
                                    <li>
                                      <a
                                        href="#"
                                        className="block px-4 py-2 hover:bg-gray-100"
                                        onClick={(e) => {
                                          e.preventDefault();

                                          this.showDropdownMenu = false;
                                          this.setShowAddCreditsModal(true);
                                          this.userId = user._id;
                                          this.credits = user.credits;
                                        }}
                                      >
                                        Edit Credits
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="#"
                                        className="block px-4 py-2 hover:bg-gray-100"
                                        onClick={(e) => {
                                          e.preventDefault();

                                          this.showDropdownMenu = false;
                                          this.setShowModifyPlanModal(true);
                                          this.userId = user._id;
                                          this.plan = user.plan;
                                        }}
                                      >
                                        Modify Plan
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="#"
                                        className="block px-4 py-2 hover:bg-gray-100"
                                        onClick={(e) => {
                                          e.preventDefault();

                                          this.showDropdownMenu = false;
                                          this.setShowChangePasswordModal(true);
                                          this.userId = user._id;
                                        }}
                                      >
                                        Change Password
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <Pagination
            items={this.users}
            total={this.total}
            perPage={this.perPage}
            currentPage={parseInt(this.currentPage) || 1}
            onPageChange={this.handlePageChange}
          />
        </Body>

        {this.showAddCreditsModal && (
          <Modal
            title="Edit Credits"
            value={this.credits}
            disableButtons={this.isUpdating}
            handleValue={(e) => {
              this.credits = e.target.value;
            }}
            handleSave={() => this.handleUpdateCredits(this.userId)}
            handleCancel={() => {
              this.setShowAddCreditsModal(false);
              this.userId = null;
              this.credits = 0;
            }}
            saveButtonText="Update Credits"
          />
        )}

        {this.showModifyPlanModal && (
          <ModifyPlanModal
            isUpdating={this.isUpdating}
            plan={this.plan}
            status={this.status}
            autoRenew={this.autoRenew}
            currentPeriodEnd={this.currentPeriodEnd}
            plans={this.plans}
            handleSave={() => this.handleUpdatePlan(this.userId)}
            handleCancel={() => {
              this.setShowModifyPlanModal(false);
              this.resetPlanFields();
            }}
            handleInputChange={(field, value) => {
              runInAction(() => {
                this[field] = value;
              });
            }}
            errorMessage={this.errorMessage}
          />
        )}

        {this.showChangePasswordModal && (
          <Modal
            title="Change Password"
            value={this.newPassword}
            disableButtons={this.isUpdating}
            handleValue={(e) => {
              this.newPassword = e.target.value;
            }}
            handleSave={() => {
              this.handleChangePassword(this.userId);
            }}
            handleCancel={() => {
              this.setShowChangePasswordModal(false);
              this.userId = null;
              this.newPassword = "";
            }}
            errorMessage={this.errorMessage}
          />
        )}

        {this.showAddUserModal && (
          <AddUserModal
            newUser={this.newUser}
            userPlans={this.userPlans}
            plans={this.plans}
            isUpdating={this.isUpdating}
            handleSave={this.handleAddUser}
            handleCancel={() => {
              runInAction(() => {
                this.showAddUserModal = false;
                this.newUser = {
                  fname: "",
                  lname: "",
                  email: "",
                  password: "",
                  plan: "Trial",
                  credits: 250,
                  gateway: "stripe",
                };
                this.errorMessage = "";
              });
            }}
            handleInputChange={(field, value) => {
              runInAction(() => {
                if (field === "plan") {
                  this.newUser = {
                    ...this.newUser,
                    [field]: value.toLowerCase(),
                  };
                } else {
                  this.newUser = {
                    ...this.newUser,
                    [field]: value,
                  };
                }
              });
            }}
            errorMessage={this.errorMessage}
          />
        )}
      </>
    );
  }

  renderSortIcon = (column) => {
    if (!this.sortBy || !this.orderBy) {
      return (
        <span className="ml-2">
          <ChevronUpIcon className="w-3 h-3 -mb-1 text-indigo-600" />
          <ChevronDownIcon className="w-3 h-3 -mt-1 text-indigo-600" />
        </span>
      );
    }

    return (
      <span className="ml-2">
        {this.sortBy === column ? (
          this.orderBy === "asc" ? (
            <ChevronUpIcon className="w-3 h-3 text-indigo-600" />
          ) : (
            <ChevronDownIcon className="w-3 h-3 text-indigo-600" />
          )
        ) : (
          <>
            <ChevronUpIcon className="w-3 h-3 -mb-1 text-indigo-600" />
            <ChevronDownIcon className="w-3 h-3 -mt-1 text-indigo-600" />
          </>
        )}
      </span>
    );
  };
}

const Modal = ({
  title,
  value,
  options,
  defaultValue,
  disableButtons,
  handleValue,
  handleSave,
  handleCancel,
  errorMessage,
  saveButtonText = "Save",
}) => {
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-600 bg-opacity-50">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="relative bg-white rounded-lg shadow-xl w-full max-w-md p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900">{title}</h3>
            <button
              onClick={handleCancel}
              className="text-gray-400 hover:text-gray-500"
            >
              <XIcon className="h-6 w-6" />
            </button>
          </div>

          <div className="mb-4">
            {options ? (
              <select
                className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-2"
                onChange={handleValue}
                defaultValue={defaultValue}
              >
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-2"
                value={value}
                onChange={handleValue}
                autoFocus={true}
              />
            )}
            {errorMessage && (
              <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
            )}
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={handleCancel}
              disabled={disableButtons}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={disableButtons}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
            >
              {disableButtons ? (
                <RefreshIcon className="h-5 w-5 animate-spin" />
              ) : (
                saveButtonText
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ModifyPlanModal = ({
  isUpdating,
  plan,
  status,
  autoRenew,
  currentPeriodEnd,
  plans,
  handleSave,
  handleCancel,
  handleInputChange,
  errorMessage,
}) => {
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-600 bg-opacity-50">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="relative bg-white rounded-lg shadow-xl w-full max-w-md p-6">
          <div className="flex items-center justify-between mb-6 border-b pb-4">
            <h3 className="text-xl text-gray-900">Modify Plan</h3>
            <button
              onClick={handleCancel}
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              <XIcon className="h-6 w-6" />
            </button>
          </div>

          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-900 mb-2">
                Plan
              </label>
              <select
                className="w-full px-3 py-2 text-gray-900 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                value={plan || ""}
                onChange={(e) => handleInputChange("plan", e.target.value)}
              >
                <option value="">Select a plan</option>
                {plans.map((p) => (
                  <option key={p} value={p}>
                    {p}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-900 mb-2">
                Status
              </label>
              <select
                className="w-full px-3 py-2 text-gray-900 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                value={status || ""}
                onChange={(e) => handleInputChange("status", e.target.value)}
              >
                <option value="">Select status</option>
                <option value="trial">Trial</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="canceled">Canceled</option>
              </select>
            </div>

            <div className="bg-gray-50 p-3 rounded-lg">
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-gray-600 rounded border-gray-300 focus:ring-gray-500"
                  checked={autoRenew}
                  onChange={(e) =>
                    handleInputChange("autoRenew", e.target.checked)
                  }
                />
                <span className="ml-2 text-sm font-medium text-gray-900">
                  Auto-renewal
                </span>
              </label>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-900 mb-2">
                Current Period End
              </label>
              <DatePicker
                selected={currentPeriodEnd}
                onChange={(date) => handleInputChange("currentPeriodEnd", date)}
                className="w-full px-3 py-2 text-gray-900 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                dateFormat="MMM d, yyyy"
                minDate={new Date()}
              />
            </div>
          </div>

          {errorMessage && (
            <div className="mt-4 p-3 bg-red-50 text-red-700 text-sm rounded-lg">
              {errorMessage}
            </div>
          )}

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={handleCancel}
              disabled={isUpdating}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={isUpdating}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
            >
              {isUpdating ? (
                <RefreshIcon className="h-5 w-5 animate-spin" />
              ) : (
                "Update Plan"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AdminTool);
