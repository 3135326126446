import { makeAutoObservable, observable, action } from "mobx";
import { updateHistory } from "./api.service";

class NavixScribeStore {
  tool = {};
  activeSection = "input";
  activeRecord = "";
  outputActiveRecord = "";
  triggerSection = "";
  selectedPersona = null;
  // timer-related states
  isRecording = false;
  recordingStartTime = 0;
  elapsedTime = 0;
  savedDuration = 0;

  constructor() {
    makeAutoObservable(this);
    this.loadSelectedPersona();
  }

  // timer actions..
  startRecording = () => {
    this.isRecording = true;
    this.recordingStartTime = Date.now();
    this.updateElapsedTime();
  };

  stopRecording = () => {
    this.isRecording = false;
    this.savedDuration = this.elapsedTime;
    this.recordingStartTime = 0;
    this.elapsedTime = 0;
  };

  updateElapsedTime = () => {
    if (this.isRecording) {
      this.elapsedTime = Date.now() - this.recordingStartTime;
      setTimeout(this.updateElapsedTime, 1000);
    }
  };

  setSelectedPersona = (val) => {
    this.selectedPersona = val;
    localStorage.setItem("selectedPersona", JSON.stringify(val)); // Save to local storage
  };

  loadSelectedPersona = () => {
    const savedPersona = localStorage.getItem("selectedPersona");
    if (savedPersona) {
      this.selectedPersona = JSON.parse(savedPersona);
    }
  };

  /** Setting the path name of tool */
  setTool = (tool) => (this.tool = tool);

  //#region Inputs Tool
  /** Used for websockets */
  output = "";
  utterances = "";

  /**
   * Used for determining the input either `text` or `audio`.
   * Set the UI toggle button
   */
  outputType = "";

  /**Active History for Input */
  inputActiveRecord = "";

  // PDF File
  inputDocument = null;

  /** Forms used to submit with Input Tool */
  inputForm = {
    title: "",
    inputText: "",
    file: "",
    filename: "",
    audioDuration: 0,
    audioDurationString: "",
    output: "",
  };

  // Input Uploads
  inputUploads = [];

  // Histories
  inputHistories = [];
  clinicalNotesHistories = [];
  questionHistories = [];
  summaryHistories = [];

  get inputOutput() {
    return this.inputForm.output;
  }

  /**
   * Set selected history as active data
   * @param {object} val
   * @returns
   */
  setInputActiveRecord = (val) => {
    console.log("setInputActiveRecord - val:", val);
    if (val.output === null) {
      console.error("setInputActiveRecord - val.output is null", val);
    }
    this.inputActiveRecord = val;
    console.log(
      "setInputActiveRecord - this.inputActiveRecord:",
      this.inputActiveRecord
    );
  };

  setInputDocument = (val) => {
    this.inputDocument = val;
  };

  /**
   * Set`s active history
   * Use for setting the active history after submission
   * @param {string|int} historyId
   * @param {string} section
   */
  setActiveHistory = (historyId, section) => {
    this.activeRecord = historyId;
    this.activeSection = section;
    // for debugging the active history and section
    console.log(
      "setActiveHistory - History ID:",
      historyId,
      "Section:",
      section
    );
  };

  /**
   * Used for setting the histories
   * instead of using local state.
   */
  setInputHistories = (val) => {
    this.inputHistories = val;
  };
  setClinicalNotesHistories = (val) => {
    this.clinicalNotesHistories = val;
  };
  setQuestionHistories = (val) => {
    this.questionHistories = val;
  };
  setSummaryHistories = (val) => {
    this.summaryHistories = val;
  };

  /**
   * Used for setting the
   * pending uploads for processing
   */
  setInputUploads = (val) => {
    this.inputUploads = val;
  };

  /**
   * Used for setting the output
   * @param {object} val
   * @param {array} utterances
   * @param {string} type
   */
  setOutput = (val, utterances, type) => {
    this.output = val;
    this.utterances = utterances;
    this.outputType = type;
  };

  /**
   * Populating `inputForm`
   * @param {string} name key of inputForm
   * @param {string} value value to be set
   */
  setInputForm = (name, value) => {
    this.inputForm[name] = value;
  };
  //#endregion Inputs Tool

  /**
   * Set data for the following tool
   * @param {history} history Output history for CreateClinicalNotes|Ask Questions|Create Summary
   */
  setOutputActiveRecord = (history) => {
    this.outputActiveRecord = history;
  };

  /**
   * Used for handling the Input Accordion
   */
  showInputAccordion = true;
  setShowInputAccordion = (val) => (this.showInputAccordion = val);

  /**
   * Update records feedbackValue
   * @param {string} value Feedback value `good`|`bad`
   * @param {string} id mongodb record id
   * @typdef {Object} StatusMessage
   * @property {boolean} success
   * @property {string} message
   * @return {StatusMessage}
   */
  *handleSaveFeedbackValue(value, id) {
    try {
      yield updateHistory(id, { feedbackValue: value });

      if (this.outputActiveRecord._id === id) {
        this.outputActiveRecord.feedbackValue = value;
      }

      return {
        success: true,
        message: "Successfully submitted your feedback.",
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: "Something went wrong!",
      };
    }
  }

  /**
   * Set which History will be triggered `Input`|`Clinical Notes`|`Question`|`Summary`
   * @param {string} val NavixScribe Tool
   * @returns
   */
  setTriggerSection = (val) => {
    this.triggerSection = val;
  };

  /** clear all data input and active records */
  clear = () => this.setTool({});
}

export const navixScribeStore = new NavixScribeStore();
