import { ChatAlt2Icon } from "@heroicons/react/solid";

const obj = {
  title: "Treatment Planning",
  desc: "Chat with our Treatment Planning AI",
  category: "Advisor",
  Icon: ChatAlt2Icon,
  permissions: ["user"],
  fromColor: "gray-500",
  to: "/ai/advisors/treatment-planning",
  api: "/ai/advisors/treatment-planning",
  welcomeMessage: `I can assist you with treatment planning for behavioral health and mental health concerns. This includes providing information on various treatment options, explaining different therapeutic approaches, discussing medication management, and offering guidance on developing a comprehensive treatment plan. If you have any questions or concerns related to these areas, feel free to ask, and I'll do my best to provide you with helpful information.`,
};

export default obj;
