import { makeAutoObservable, observable, action } from "mobx";

class ClinicalNoteWriterStore {
  @observable histories = [];
  @observable loading = false;
  @observable clinicalNoteOutput = "";
  @observable customTemplates = [];
  @observable selectedCustomTemplate = null;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setHistories(histories) {
    this.histories = histories;
  }

  @action
  setCustomTemplates(customTemplates) {
    this.customTemplates = customTemplates;
  }

  @action
  setSelectedCustomTemplate(customTemplate) {
    this.selectedCustomTemplate = customTemplate;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  @action
  setClinicalNoteOutput(clinicalNoteOutput) {
    this.clinicalNoteOutput = clinicalNoteOutput;
  }
}

export const clinicalNoteWriterStore = new ClinicalNoteWriterStore();
