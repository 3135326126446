import { useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import History from "./History";
import UploadingList from "./UploadingList";

export const AccordionItem = ({
  title,
  description,
  open,
  onClick,
  Component,
  ...props
}) => {
  const ref = useRef(null);
  const iconClass = `transform transition-transform duration-500 ${open ? "rotate-180" : "rotate-0"}`; //I defined all aspects of the icon's animated behavior in one place.
  const descClass = open ? "pb-2 max-h-4/5" : "overflow-hidden max-h-0";
  const contentClass = open ? "py-2 max-h-4/5" : "overflow-hidden max-h-0";

  const isActive = `navix-scribe-accordion-item${
    open ? "--active divide-y divide-ngray" : ""
  }`;

  return (
    <div className={`border rounded-lg p-4 mb-1 ${isActive}`} ref={ref}>
      <div
        className="flex flex-col cursor-pointer"
        onClick={() => {
          // Only triggers when newly selected input tool is being opened, since users will likely need to see the content of the tool they are opening, closing doesn't need a view adjustment.
          if (!open) {
            setTimeout(() => {
              ref.current.scrollIntoView({
                //scrollIntoView provides the element-centric solution to auto scrolling
                behavior: "smooth",
                block: "nearest",
              });
            }, 250); //To account for any animations/state transitions that need to complete
          }
          onClick && onClick();
        }}
      >
        <div className="flex justify-between items-center">
          <h1 className="text-xl text-navix font-semibold capitalize truncate">
            {title}
          </h1>
          <ChevronDownIcon className={`w-5 h-5 ${iconClass}`} />
        </div>
        <p
          className={`text-base truncate transition-[max-height] ease-in duration-500 ${descClass}`}
        >
          {description}
        </p>
      </div>
      {Component && (
        <div
          className={`transition-[max-height] ease-in duration-500 ${contentClass}`}
        >
          <Component />
        </div>
      )}
      {title === "Inputs" && open && <UploadingList />}
      <div className={contentClass}>{open && <History {...props} />}</div>
    </div>
  );
};
