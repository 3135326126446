import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import Loader from "../../../components/Loader";
import styled from "styled-components";
import moment from "moment";
import { fetchThreadHistories } from "../api.service.v2";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  PlusIcon,
  ChatIcon,
  DotsVerticalIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { runInAction } from "mobx";

const HistoryPanel = inject(
  "store",
  "navixScribeV2Store"
)(
  observer(({ store, navixScribeV2Store, onToggle }) => {
    const [histories, setHistories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [selectedThreadId, setSelectedThreadId] = useState(null);
    const [showDelete, setShowDelete] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteThreadId, setDeleteThreadId] = useState(null);
    const deleteDropdownRef = useRef(null);

    const titleRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    const toggleDelete = (threadId, event) => {
      event.stopPropagation();
      setShowDelete((prev) => (prev === threadId ? null : threadId));
    };

    const handleDeleteClick = (threadId, event) => {
      event.stopPropagation();
      console.log(`Delete button clicked for threadId: ${threadId}`);
      setDeleteThreadId(threadId);
      setIsDeleteModalOpen(true);
    };

    const handleDelete = async () => {
      if (deleteThreadId) {
        try {
          console.log(`Confirming delete for threadId: ${deleteThreadId}`);
          const history = histories.find((h) => h._id === deleteThreadId);
          if (history) {
            console.log(`Found history: ${JSON.stringify(history)}`);
            const response = await store.api.delete(
              `/histories/${history._id}`
            );
            console.log(`API response: ${response.status}`);
            if (response.status === 200) {
              setHistories((prevHistories) =>
                prevHistories.filter((h) => h._id !== history._id)
              );
              console.log(`History deleted: ${history._id}`);
            } else {
              console.error("Failed to delete history");
            }
          } else {
            console.error("History not found for threadId:", deleteThreadId);
            console.log("Available histories:", histories);
          }
        } catch (error) {
          console.error("Error deleting history:", error);
        } finally {
          setIsDeleteModalOpen(false);
          setDeleteThreadId(null);
        }
      } else {
        console.error("No deleteThreadId set");
      }
    };

    const fetchHistories = async () => {
      try {
        const response = await fetchThreadHistories();
        setHistories(response.result);
      } catch (error) {
        console.error("Error fetching histories:", error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchHistories();
    }, []);

    useEffect(() => {
      const refreshHistories = () => {
        fetchHistories();
      };

      navixScribeV2Store.on("threadAdded", refreshHistories);

      return () => {
        navixScribeV2Store.off("threadAdded", refreshHistories);
      };
    }, [navixScribeV2Store]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          deleteDropdownRef.current &&
          !deleteDropdownRef.current.contains(event.target) &&
          !event.target.closest("[data-ignore-outside-click]")
        ) {
          setShowDelete(null);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [deleteDropdownRef]);

    const handleHistoryClick = async (threadId) => {
      try {
        setSelectedThreadId(threadId);
        await navixScribeV2Store.selectThread(threadId);
      } catch (error) {
        console.error("Error selecting thread:", error);
      }
    };

    const toggleCollapse = () => {
      const newCollapsedState = !isCollapsed;
      setIsCollapsed(newCollapsedState);
      onToggle(newCollapsedState);
    };

    useEffect(() => {
      if (titleRef.current) {
        const titleWidth = titleRef.current.scrollWidth;
        setIsTruncated(titleWidth > 179);
      }
    }, [histories]);

    if (loading) {
      return (
        <div className="flex justify-center items-center">
          <Loader active={true} className="w-36 h-36" />
        </div>
      );
    }

    return (
      <Wrapper isCollapsed={isCollapsed}>
        <CollapseButton onClick={toggleCollapse}>
          {isCollapsed ? (
            <ChevronRightIcon className="w-6 h-6 text-white" />
          ) : (
            <ChevronLeftIcon className="w-6 h-6 text-white" />
          )}
        </CollapseButton>
        <Container>
          <NewEncounterButton
            isCollapsed={isCollapsed}
            onClick={() => window.location.reload()}
          >
            <PlusIcon className="w-6 h-6 text-gray-700" />
            {!isCollapsed && <span>New Encounter</span>}
          </NewEncounterButton>
          <RecentsLabel>Recents</RecentsLabel>
          <HistoryList>
            {histories.length > 0 ? (
              histories.map((history) => (
                <HistoryItem
                  key={history._id}
                  onClick={() => handleHistoryClick(history.threadId)}
                  isActive={selectedThreadId === history.threadId}
                  isCollapsed={isCollapsed}
                >
                  <ItemContent>
                    <IconWrapper>
                      <ChatIcon className="w-6 h-6" />
                    </IconWrapper>
                    {!isCollapsed && (
                      <TitleWrapper>
                        <h3
                          ref={titleRef}
                          className={isTruncated ? "truncate" : ""}
                        >
                          {history.title}
                        </h3>
                        <p>
                          {moment(history.created).format(
                            "MMM DD, YYYY h:mm A"
                          )}
                        </p>
                      </TitleWrapper>
                    )}
                  </ItemContent>
                  {!isCollapsed && (
                    <IconWrapper className="dots" ref={deleteDropdownRef}>
                      <DotsVerticalIcon
                        className="w-6 h-6"
                        onClick={(event) => toggleDelete(history._id, event)}
                      />
                      {showDelete === history._id && (
                        <DeleteButton
                          data-ignore-outside-click
                          onClick={(event) =>
                            handleDeleteClick(history._id, event)
                          }
                        >
                          <TrashIcon className="w-4 h-4" />
                          Delete
                        </DeleteButton>
                      )}
                    </IconWrapper>
                  )}
                </HistoryItem>
              ))
            ) : (
              <NoHistoriesMessage>
                <NoHistoriesIcon>🗊</NoHistoriesIcon>
                <p>No histories available.</p>
                <p>Start a new encounter to begin!</p>
              </NoHistoriesMessage>
            )}
          </HistoryList>
        </Container>
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDelete}
        />
      </Wrapper>
    );
  })
);

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  transition: width 0.3s ease;
  width: ${(props) => (props.isCollapsed ? "90px" : "300px")};
  background-color: #f8f8f8;
  height: 100vh;
  z-index: 10;
`;

const CollapseButton = styled.div`
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  background-color: #5c4bdb;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
`;

const Container = styled.div`
  padding: ${(props) => (props.isCollapsed ? "0" : "20px 10px")};
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: ${(props) => (props.isCollapsed ? "center" : "flex-start")};
`;

const NewEncounterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 20px;
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  width: ${(props) => (props.isCollapsed ? "40px" : "160px")};

  &:hover {
    background-color: #f3f4f6;
  }

  span {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
`;

const RecentsLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
`;

const HistoryList = styled.div`
  flex-grow: 0.2;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoHistoriesMessage = styled.div`
  text-align: center;
  color: #666;
  font-size: 14px;
  line-height: 1.4;
  max-width: 200px;
  margin: 0 auto;
`;

const NoHistoriesIcon = styled.div`
  font-size: 48px;
  margin-bottom: 16px;
  color: #999;
`;

const HistoryItem = styled.div`
  margin-bottom: 15px;
  padding: ${(props) => (props.isCollapsed ? "8px" : "10px")};
  background-color: ${(props) => (props.isActive ? "#e4dff5" : "transparent")};
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  flex-grow: 1;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 1;
`;

const TitleWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  max-width: 179px;

  h3 {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    position: relative;
  }

  h3.truncate {
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 20px;
      background: linear-gradient(
        to left,
        rgba(248, 248, 248, 1),
        rgba(248, 248, 248, 0)
      );
    }
  }

  p {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    margin-top: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  transition:
    visibility 0s,
    opacity 0.3s ease;

  &:hover {
    background-color: #f3f4f6;
  }

  .w-4 {
    margin-right: 5px;
  }
`;

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this history item?
        </ModalBody>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <ButtonContainer>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <ConfirmDeleteButton onClick={onConfirm}>Delete</ConfirmDeleteButton>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 300px;
`;

const ModalHeader = styled.h2`
  margin-bottom: 20px;
`;

const ModalBody = styled.div`
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
`;

const CancelButton = styled(Button)`
  background-color: #f0f0f0;
  color: #333;
  border: none;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ConfirmDeleteButton = styled(Button)`
  background-color: #dc3545;
  color: white;
  border: none;

  &:hover {
    background-color: #c82333;
  }
`;

export default HistoryPanel;
