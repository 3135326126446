import { inject, observer } from "mobx-react";
import { Suspense, lazy, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Loader from "../../components/Loader";
import { BookOpenIcon } from "@heroicons/react/outline";
import HistoryPanel from "./components/HistoryPanel";
import RecordingComponent from "./components/RecordingComponent";
import InteractionPanel from "./components/InteractionPanel";
import Modal from "../../components/Modal";

const Output = lazy(() => import("./components/Output"));

const NavixScribe = inject("store")(
  observer(({ store, navixScribeV2Store, location }) => {
    const [loading, setLoading] = useState(false);
    const [threadId, setThreadId] = useState(null);
    const [, forceUpdate] = useState();
    const [isHistoryPanelCollapsed, setIsHistoryPanelCollapsed] =
      useState(true);
    const [showModal, setShowModal] = useState(false);
    const [videoSrc, setVideoSrc] = useState(
      "https://www.youtube.com/embed/2ugYps6mWdY"
    );

    const formattedName = `${store.profile.fname.charAt(0).toUpperCase() + store.profile.fname.slice(1)}${
      store.profile.lname
        ? ` ${store.profile.lname.charAt(0).toUpperCase() + store.profile.lname.slice(1)}`
        : ""
    }`;

    // useEffect(() => {
    //   if (!store.hasCurrentToolAccess()) {
    //     window.location.href = "/";
    //   }

    //   navixScribeV2Store.setTool(store.getToolByUrl(location.pathname));
    //   return () => {
    //     navixScribeV2Store.clear();
    //   };
    // }, []);

    useEffect(() => {
      forceUpdate({});
    }, [navixScribeV2Store.selectedThread]);

    useEffect(() => {
      const abortController = new AbortController();
      const signal = abortController.signal;
      if (navixScribeV2Store.activeRecord !== "") {
        (async () => {
          try {
            setLoading(true);
            const { data } = await store.api.get(
              `/histories/navix-scribe/${navixScribeV2Store.activeRecord}`,
              { signal }
            );
            const history = data.history;

            navixScribeV2Store.setInputDocument(null);

            if (navixScribeV2Store.activeSection === "Input") {
              if (history.subToolAction === "text" && history.filename) {
                const document = await store.api({
                  url: `/histories/navix-scribe/${navixScribeV2Store.activeRecord}/document`,
                  responseType: "arraybuffer",
                  responseEncoding: "binary",
                  headers: {
                    "Content-Type": "application/pdf",
                  },
                });

                history.output = null;

                navixScribeV2Store.setInputDocument(document);
              }

              navixScribeV2Store.setInputActiveRecord(history);
              navixScribeV2Store.setOutputActiveRecord("");
            } else {
              navixScribeV2Store.setOutputActiveRecord(history);
            }
          } catch (e) {
            console.log(e);
          } finally {
            setTimeout(() => {
              setLoading(false);
            }, 500);
          }
        })();
      }

      return () => {
        abortController.abort();
      };
    }, [navixScribeV2Store.activeRecord]);

    useEffect(() => {
      const checkFirstInteraction = async () => {
        if (store.profile && !store.profile.firstNavixScribeV2Interaction) {
          setShowModal(true);
          await store.api.patch(`/user/${store.profile._id}`, {
            firstNavixScribeV2Interaction: true,
          });
        }
      };
      checkFirstInteraction();
    }, [store.profile]);

    const handleHistoryPanelToggle = (isCollapsed) => {
      setIsHistoryPanelCollapsed(isCollapsed);
    };

    const closeModal = () => {
      setShowModal(false);
      setVideoSrc("");
    };

    return (
      <div>
        <Helmet>
          <title>NavixScribe V2</title>
        </Helmet>

        <div className="h-screen flex">
          <HistoryPanel onToggle={handleHistoryPanelToggle} />

          <div
            className="flex-grow flex flex-col transition-all duration-300"
            style={{
              marginLeft: isHistoryPanelCollapsed ? "90px" : "300px",
            }}
          >
            <div className="flex-grow overflow-y-auto p-4 bg-white rounded-md shadow-lg">
              {navixScribeV2Store.selectedThread ? (
                <Suspense
                  fallback={
                    <div className="flex justify-center items-center align-middle h-full">
                      <div>
                        <Loader active={true} className="w-36 h-36" />
                        <p className="text-xs text-gray-500">
                          Please wait for a moment...
                        </p>
                      </div>
                    </div>
                  }
                >
                  <Output loading={loading} />
                </Suspense>
              ) : (
                <div className="flex flex-col items-center justify-center h-full">
                  <h1
                    className="text-violet-900 mb-4 font-bold"
                    style={{ fontSize: "clamp(2rem, 4vw, 3.75rem)" }}
                  >
                    Hi, {formattedName}!
                  </h1>
                  <p
                    className="text-gray-500 mb-8"
                    style={{ fontSize: "clamp(1rem, 2vw, 1.875rem)" }}
                  >
                    Hit record and let the magic begin.
                  </p>
                  <RecordingComponent navixScribeV2Store={navixScribeV2Store} />
                </div>
              )}
            </div>

            <div className="sticky bottom-0 p-4 border-t z-10 bg-white">
              <InteractionPanel navixScribeV2Store={navixScribeV2Store} />
            </div>
          </div>
        </div>

        <Modal open={showModal} onClose={closeModal} variant="video">
          <div className="flex items-center mb-2">
            <BookOpenIcon className="h-6 w-6 mr-2 text-gray-800" />
            <h2 className="text-xl font-bold">Guide</h2>
          </div>
          <p className="mb-2">
            Learn how to get started with NavixScribe V2 by watching this video:
          </p>
          <div
            className="relative w-full"
            style={{
              paddingBottom: "56.25%",
              height: 0,
              overflow: "hidden",
              maxWidth: "100%",
              background: "#000",
            }}
          >
            <iframe
              src={videoSrc}
              frameBorder="0"
              allowFullScreen
              title="NavixScribe V2 guide"
              className="absolute top-0 left-0 w-full h-full"
              style={{ border: 0 }}
            ></iframe>
          </div>
        </Modal>
      </div>
    );
  })
);

export default NavixScribe;
