import React from "react";
import { PlusIcon } from "@heroicons/react/solid";
const ClinicalSidebar = ({ histories, onNewEncounter, onSelectHistory }) => {
  return (
    <div className="w-64 bg-gray-100 p-4 h-full">
      <button
        className="w-full bg-white text-gray-800 font-bold py-2 rounded-2xl mb-4 shadow-lg flex items-center justify-center space-x-2 hover:bg-purple-300"
        onClick={onNewEncounter}
      >
        <PlusIcon className="w-4 h-4" />
        <span>New Encounter</span>
      </button>
      <div>
        <h2 className="text-lg font-bold mb-2">Recents</h2>
        {histories.map((entry, index) => (
          <div
            key={index}
            className="bg-white p-3 mb-2 rounded shadow cursor-pointer hover:bg-purple-100"
            onClick={() => onSelectHistory(entry)}
          >
            <p className="font-semibold truncate">{entry.clientName}</p>
            <p className="text-sm text-gray-500">{entry.noteDate}</p>
            <p className="text-sm text-gray-500">{entry.duration}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClinicalSidebar;
