import { ViewListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Users",
  desc: "Access and manage user accounts.",
  Icon: ViewListIcon,
  fromColor: "gray-500",
  to: "/admin/users",
  api: "/admin/users",
  permission: "admin",
};

export default obj;
