import { useEffect, useState } from "react";

const useIsWebView = () => {
  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS WebView detection
    const isIOSWebView = /iPhone|iPod|iPad.*AppleWebKit(?!.*Safari)/i.test(
      userAgent
    );

    // Android WebView detection
    const isAndroidWebView = /Android.*(wv|\.0\.0\.0)/i.test(userAgent);

    // Flutter WebView detection (MacOS and Windows)
    const isFlutterWebView = /Flutter/i.test(userAgent);

    setIsWebView(isIOSWebView || isAndroidWebView || isFlutterWebView);
  }, []);

  return isWebView;
};

export default useIsWebView;
