import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";

export const Collapsable = ({ content, title }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="border border-gray-300 my-2 rounded">
      <div
        className={`flex justify-between p-2 cursor-pointer ${isCollapsed && "border-b"}`}
        onClick={toggleCollapse}
      >
        {title && (
          <h3 className="text-gray-600 font-medium text-md">{title}</h3>
        )}
        <ChevronDownIcon
          className="h-6 w-6 text-gray-500 transform transition-transform duration-300"
          style={{ transform: `rotate(${isCollapsed ? "180" : "0"}deg)` }}
        />
      </div>
      {isCollapsed && <div className="p-2 whitespace-pre-wrap">{content}</div>}
    </div>
  );
};
