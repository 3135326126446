import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { computed } from "mobx";
import { observer, inject } from "mobx-react";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { detect } from "detect-browser";
import {
  DatabaseIcon,
  UserCircleIcon,
  LightBulbIcon,
  XIcon,
  BookOpenIcon,
  QuestionMarkCircleIcon,
  CogIcon,
  PlayIcon,
} from "@heroicons/react/outline";
import { IconDashboard } from "./Icons";
import Body from "./components/Body";
import Logo from "./components/Logo";
import { getAppType } from "./helper";
import Modal from "./components/Modal";
import SettingsModal from "./core/NavixScribeV2/components/SettingsModal";
import YouTube from "react-youtube";
import TutorialButton from "./components/TutorialButton";

const browser = detect();

function HeaderExpand(props) {
  const location = useLocation();

  return (
    <SuperHeader active={location.pathname === "/" ? true : false}>
      {props.children}
    </SuperHeader>
  );
}

@inject("store", "navixScribeV2Store")
@observer
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSupportModal: false,
      showSettingsDropdown: false,
      showSettingsModal: false,
      showTutorialModal: false,
      selectedVideoId: null,
      videoLastTimestamp: 0,
      isYouTubeApiReady: false,
      videoTimestamps: {},
      currentVideoId: null,
    };

    this.dropdownTimeout = null;
    this.playerRef = null;

    if (this.props.location.pathname === "/signup") {
      this.props.history.push("/");
    }

    if (this.props.location.pathname === "/login") {
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.shortcutHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.shortcutHandler);
  }

  shortcutHandler = (e) => {
    if (e.ctrlKey && e.keyCode === 75) {
      e.preventDefault();
      document.getElementById("q").focus();
      document.getElementById("q").select();
    }
  };

  onKeyUp = (e) => {
    if (this.props.location.pathname !== "/search") {
      this.props.history.push("/search");
    }

    if (e.keyCode === 8) {
      if (this.props.store.toolsKeyword === "") {
        this.props.history.push("/");
      }
    }
  };

  @computed get fromColor() {
    if (this.props.store.profile.credits <= 0) {
      return "insufficient-credits text-white";
    }

    switch (this.props.store.profile.status) {
      case "trialing":
      case "active":
      case "incomplete":
        return "";
    }

    return "bg-navix-lavender text-navix-ivory";
  }

  handleGuideButtonClick = () => {
    this.props.store.openGuideModal();
  };

  handleSupportButtonClick = () => {
    this.setState({ showSupportModal: true });
  };

  closeSupportModal = () => {
    this.setState({ showSupportModal: false });
  };

  showSettingsDropdown = () => {
    clearTimeout(this.dropdownTimeout);
    this.setState({ showSettingsDropdown: true });
  };

  hideSettingsDropdown = () => {
    this.dropdownTimeout = setTimeout(() => {
      this.setState({ showSettingsDropdown: false });
    }, 300);
  };

  openSettingsModal = () => {
    clearTimeout(this.dropdownTimeout);
    this.setState({
      showSettingsModal: true,
      showSettingsDropdown: false,
    });
  };

  closeSettingsModal = () => {
    this.setState({ showSettingsModal: false });
  };

  handleTutorialButtonClick = () => {
    this.setState({ showTutorialModal: true });
  };

  closeTutorialModal = () => {
    if (this.playerRef && this.state.isYouTubeApiReady) {
      const currentTime = this.playerRef.getCurrentTime();
      this.setState((prevState) => ({
        showTutorialModal: false,
        videoTimestamps: {
          ...prevState.videoTimestamps,
          [prevState.currentVideoId]: currentTime,
        },
        currentVideoId: null,
      }));
    } else {
      this.setState({ showTutorialModal: false, currentVideoId: null });
    }
  };

  onReady = (event) => {
    this.playerRef = event.target;
    this.setState({ isYouTubeApiReady: true });
    const lastTimestamp =
      this.state.videoTimestamps[this.state.currentVideoId] || 0;
    if (lastTimestamp > 0) {
      this.playerRef.seekTo(lastTimestamp);
    }
  };

  render() {
    const {
      showSupportModal,
      showSettingsDropdown,
      showSettingsModal,
      showTutorialModal,
      selectedVideoId,
      videoLastTimestamp,
    } = this.state;

    const dashboardVideos = [
      { id: "zd-tPM2gmUw", title: "Navix Scribe + Interact" },
      { id: "WAGrTtfEkGA", title: "Navigating Navix Scribe 🚀" },
      { id: "tK712pTkVjk", title: "Welcome to Navix AI" },
      { id: "QA4moPa1hgM", title: "Customizing Navix Scribe + Interact" },
      { id: "4XdhsPlaJ7Q", title: "Custom Templates" },
      { id: "XpdTBtBLvYY", title: "Update Subscription & Payment Information" },
    ];

    const navixScribeV2Videos = [
      { id: "JrBVqrQs0tw", title: "How to Upload PDFs into NavixScribe 🗒️" },
      { id: "JevqZQp6mOE", title: "Uploading Pre-recorded and Audio Files 🎙️" },
      { id: "l1_8n35_gZs", title: "Personalized Set-up" },
      { id: "9x46-sH47ns", title: "Live Recordings in NavixScribe" },
      { id: "4XdhsPlaJ7Q", title: "Custom Templates" },
      { id: "XpdTBtBLvYY", title: "Update Subscription & Payment Information" },
    ];

    const isNavixScribeV2Route =
      this.props.location.pathname === "/ai/audio/navixscribe-v2";
    const isDashboardRoute = this.props.location.pathname === "/";

    return (
      <>
        <Textarea
          readOnly
          name="copy-textarea"
          id="copy-textarea"
          value={this.props.store.copyToClipboardText}
        />

        {getAppType == "main" && (
          <HeaderExpand>
            <Body className="px-4 py-4 md:px-28 md:py-8 lg:py-12 flex items-center flex-1">
              <div className="mr-4 ">
                <NavLink to="/">
                  <Logo />
                </NavLink>
              </div>

              <div>
                <div className="text-4xl relative font-medium text-transparent bg-clip-text text-navix-golden inline-block pl-20 md:pl-0">
                  NavixAI
                  <span className="font-normal hidden sm:inline">
                    {" "}
                    - Tools for Healthcare Professionals
                  </span>
                </div>

                <div className="hidden md:block text-xl text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-gray-200">
                  Select a tool from our Library
                </div>

                {/* Temporarily hide credits display */}

                {/* <div className="flex">
                  <div
                    className={`items-center flex ${
                      this.props.store.profile.credits
                        ? " bg-gray-100 text-gray-500"
                        : " bg-red-100 text-red-500"
                    } text-sm rounded-md px-3 py-1 font-medium my-2 mr-2 ml-20 md:ml-0`}
                  >
                    <DatabaseIcon className="w-4 h-4 mr-2" />
                    {Math.max(this.props.store.profile.credits, 0)}
                    &nbsp;
                    <span className="hidden lg:block">credits remain</span>
                  </div>
                </div> */}
              </div>
            </Body>
          </HeaderExpand>
        )}

        <div className="border-b border-gray-300 bg-gray-50 shadow-sm">
          <div className="container flex mx-auto px-4 md:px-28 select-none">
            <NavLink
              to="/"
              exact
              tabIndex={-1}
              onClick={() => (this.props.store.toolsKeyword = "")}
              activeClassName="bg-navix-turquoise hover:bg-navix-lavender text-gray-800 transition"
              className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 my-2 mr-2 cursor-pointer hover:bg-navix-lavender rounded-md font-medium transition items-center"
              style={{
                height: getAppType == "hub" ? "58px" : "inherit",
              }}
            >
              <IconDashboard className="w-7 h-7 lg:mr-4 transition" />
              <div className="hidden lg:block">Tools</div>
            </NavLink>

            <div
              className={`relative text-navix-ivory focus-within:text-green-500 flex flex-1 ${
                getAppType == "hub" ? "items-center justify-center" : ""
              }`}
            >
              {getAppType == "hub" && (
                <div className="text-2xl relative font-medium text-transparent bg-clip-text text-navix-golden inline-block sm:pl-20 md:pl-0">
                  NavixAI
                </div>
              )}
            </div>

            <div className="relative">
              <NavLink
                to="/my-profile"
                exact
                tabIndex="-1"
                activeClassName="bg-navix-lavender hover:bg-navix-golden text-green-800 transition"
                className={`text-lg flex py-3 px-6 xl:py-4 xl:px-8 my-2 ml-2 cursor-pointer ${this.fromColor} hover:bg-navix-turquoise rounded-md font-medium transition items-center`}
                style={{
                  height: getAppType == "hub" ? "58px" : "inherit",
                }}
                onMouseEnter={
                  isNavixScribeV2Route ? this.showSettingsDropdown : null
                }
                onMouseLeave={
                  isNavixScribeV2Route ? this.hideSettingsDropdown : null
                }
              >
                <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
                <div className="hidden lg:block"> My Profile</div>
              </NavLink>

              {isNavixScribeV2Route && showSettingsDropdown && (
                <div
                  className="absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                  onMouseEnter={this.showSettingsDropdown}
                  onMouseLeave={this.hideSettingsDropdown}
                >
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <button
                      onClick={this.openSettingsModal}
                      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
                      role="menuitem"
                    >
                      <CogIcon
                        className="mr-3 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Settings
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Temporarily hide credits display */}

            {/* {getAppType == "hub" && (
              <div className="flex">
                <div
                  className={`items-center flex ${
                    this.props.store.profile.credits
                      ? " bg-gray-100 text-gray-500"
                      : " bg-red-100 text-red-500"
                  } text-sm rounded-md px-3 py-1 font-medium my-2 mr-2`}
                >
                  <DatabaseIcon className="w-4 h-4 mr-2" />
                  {Math.max(this.props.store.profile.credits, 0)}
                  &nbsp;
                  <span className="hidden lg:block">credits remain</span>
                </div>
              </div>
            )} */}

            {this.props.location.pathname === "/ai/audio/transcriber-new" && (
              <button
                className={`flex items-center text-lg py-3 px-6 lg:py-4 lg:px-8 my-2 ml-2 cursor-pointer ${this.fromColor} hover:bg-navix-turquoise rounded-md font-medium transition items-center`}
                onClick={this.handleGuideButtonClick}
              >
                <BookOpenIcon className="h-5 w-5 mr-2" />
                <span className="hidden lg:block">Guide</span>
              </button>
            )}

            {(isNavixScribeV2Route || isDashboardRoute) && (
              <TutorialButton
                videos={
                  isNavixScribeV2Route ? navixScribeV2Videos : dashboardVideos
                }
                onVideoSelect={this.handleTutorialVideoSelect}
              />
            )}

            <button
              className={`flex items-center text-lg py-3 px-6 lg:py-4 lg:px-8 my-2 ml-2 cursor-pointer ${this.fromColor} hover:bg-navix-turquoise rounded-md font-medium transition items-center`}
              onClick={this.handleSupportButtonClick}
            >
              <QuestionMarkCircleIcon className="h-5 w-5 mr-2" />
              <span className="hidden lg:block">Support</span>
            </button>
          </div>
        </div>

        <Banner />

        {this.props.children}

        <Modal
          open={this.props.store.showGuideModal}
          onClose={this.props.store.closeGuideModal}
          variant="video"
        >
          <div className="flex items-center mb-2">
            <BookOpenIcon className="h-6 w-6 mr-2 text-gray-800" />
            <h2 className="text-xl font-bold">Guide</h2>
          </div>
          <p className="mb-2">
            Learn how to get started with NavixScribe by watching this video:
          </p>
          <div
            className="relative w-full"
            style={{
              paddingBottom: "56.25%",
              height: 0,
              overflow: "hidden",
              maxWidth: "100%",
              background: "#000",
            }}
          >
            <iframe
              src="#"
              frameBorder="0"
              allowFullScreen
              title="NavixScribe guide"
              className="absolute top-0 left-0 w-full h-full"
              style={{ border: 0 }}
            ></iframe>
          </div>
        </Modal>

        <Modal open={showSupportModal} onClose={this.closeSupportModal}>
          <div className="text-center">
            <h3 className="text-lg w-full mb-4 text-left font-semibold tracking-wide  text-slate-800">
              Support Portal
            </h3>
            <p className="text-left text-lg tracking-wide font-semibold text-zinc-900 mb-4">
              If you have any concerns or questions, please contact us.
            </p>
            <div className="space-y-2">
              <div className="flex gap-3 items-start">
                <span className="w-2/4 text-lg font-semibold text-left text-zinc-900">
                  Contact Number:
                </span>
                <span className="w-1/4 text-md text-zinc-500">
                  (831) 604-1827
                </span>
              </div>
              <div className="flex gap-3 items-start">
                <span className="w-2/4 text-lg font-semibold text-left text-zinc-900">
                  Email:
                </span>
                <span className="w-1/4 text-md text-zinc-500">
                  support@navixhealth.com
                </span>
              </div>
              <div className="flex gap-3 items-center">
                <span className="w-2/4 text-lg font-semibold text-left text-zinc-900">
                  Support Portal:
                </span>
                <a
                  href="https://navixhealth.atlassian.net/servicedesk/customer/portal/3"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border rounded-md border-purple-400 text-purple-600 text-center font-normal text-base p-1 inline-block w-2/4"
                >
                  Support Portal
                </a>
              </div>
              <div className="flex gap-3 items-center">
                <span className="w-2/4 text-lg text-left font-semibold text-zinc-900">
                  Schedule Onboarding:
                </span>
                <a
                  href="https://calendly.com/d/zyn-7gp-xv6/navixai-demo"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border rounded-md border-purple-400 text-purple-600 text-center font-normal text-base p-1 inline-block w-2/4"
                >
                  Schedule Onboarding
                </a>
              </div>
            </div>
          </div>
        </Modal>

        <SettingsModal
          isOpen={showSettingsModal}
          onClose={this.closeSettingsModal}
          navixScribeV2Store={this.props.navixScribeV2Store}
        />

        <Modal
          open={showTutorialModal}
          onClose={this.closeTutorialModal}
          variant="video"
        >
          <div className="flex items-center mb-2">
            <PlayIcon className="h-6 w-6 mr-2 text-gray-800" />
            <h2 className="text-xl font-bold">Tutorial</h2>
          </div>
          <p className="mb-2">Watch this tutorial video:</p>
          <div
            className="relative w-full"
            style={{
              paddingBottom: "56.25%",
              height: 0,
              overflow: "hidden",
              maxWidth: "100%",
              background: "#000",
            }}
          >
            {showTutorialModal && this.state.currentVideoId && (
              <YouTube
                videoId={this.state.currentVideoId}
                opts={{
                  height: "100%",
                  width: "100%",
                  playerVars: {
                    autoplay: 1,
                    start: Math.floor(
                      this.state.videoTimestamps[this.state.currentVideoId] || 0
                    ),
                  },
                }}
                onReady={this.onReady}
                className="absolute top-0 left-0 w-full h-full"
              />
            )}
          </div>
        </Modal>
      </>
    );
  }

  handleTutorialVideoSelect = (video) => {
    this.setState((prevState) => ({
      showTutorialModal: true,
      currentVideoId: video.id,
      videoLastTimestamp: prevState.videoTimestamps[video.id] || 0,
    }));
  };
}

const Textarea = styled.textarea`
  position: fixed;
  right: -9990px;
  top: -9990px;
`;

const SuperHeader = styled.div`
  height: 150px;
  background: #374650;
  margin-top: ${(props) => (props.active ? "0px" : "-150px")};
  display: ${(props) => (props.hidden ? "hidden" : "flex")};
  background-image: url(${require("./pattern-dots.svg").default});
  background-size: auto 50%;
  background-position: 20px 20px;
  background-repeat: no-repeat;
  position: relative;
`;

const Banner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (browser) {
      setShowBanner(browser.name == "safari");
    }
  }, []);

  return (
    <>
      {showBanner && (
        <div
          id="alert-1"
          className="flex items-center justify-between bg-blue-50 p-4 text-blue-800"
        >
          <div className="flex items-center text-sm font-medium">
            <LightBulbIcon className="h-4 w-4 mr-2" />
            Friendly tip: Our platform works best with Google Chrome or other
            Chromium-based browsers. You might encounter a few hiccups using
            Safari.
          </div>
          <button
            type="button"
            className="-m-1.5 inline-flex h-8 w-8 items-center justify-center rounded-lg bg-blue-50 p-1.5 hover:bg-blue-200 focus:ring-2 focus:ring-blue-400"
            data-dismiss-target="#alert-1"
            onClick={() => {
              setShowBanner(false);
            }}
          >
            <XIcon className="h-4 w-4" />
          </button>
        </div>
      )}
    </>
  );
};

export default withRouter(Header);
