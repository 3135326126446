import { useState } from "react";
import { AccordionItem } from "./AccordionItem";

export const AccordionList = ({ navixScribeStore, data }) => {
  const [accordionData, setAccordionData] = useState(
    data.map((item) => ({ ...item }))
  );

  const handleItemClick = (index) => {
    const updatedData = accordionData.map((item, i) => ({
      ...item,
      open: i === index ? !item.open : false,
    }));
    setAccordionData(updatedData);
  };

  return (
    <div>
      {accordionData.map((item, index) => (
        <AccordionItem
          key={index}
          onClick={() => handleItemClick(index)}
          inputActiveRecord={navixScribeStore.inputActiveRecord}
          {...item}
        />
      ))}
    </div>
  );
};
