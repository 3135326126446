import { reaction } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import Loader from "../../../components/Loader";

const UploadingList = inject(
  "store",
  "navixScribeStore"
)(
  observer(({ store, navixScribeStore }) => {
    return (
      navixScribeStore.inputUploads.length > 0 && (
        <div>
          <div className="flex justify-between items-center">
            <h1 className="text-gray-500">Currently Uploading...</h1>
          </div>

          <div className="space-y-2">
            {navixScribeStore.inputUploads.map((item, index) => (
              <ListItem key={item._id} className="cursor-pointer">
                <h1
                  className="truncate font-semibold w-1/3"
                  style={{ flexGrow: 1 }}
                >
                  {item.title}
                </h1>
                <Loader active className="w-6 h-6 mr-1" />
              </ListItem>
            ))}
          </div>
        </div>
      )
    );
  })
);

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  background: #8b23ef1c;
  padding: 0.5rem 0.5rem;
  border-radius: 6px;
  ${(props) =>
    props.active
      ? css`
          border: 2px solid #8b23ef;
        `
      : null};
`;

export const StyledReactPaginate = styled(ReactPaginate)`
  margin-top: 12px;
  display: flex;
  justify-content: end;
  flex-wrap: nowrap;
  column-gap: 5px;

  text-align: center;
  color: #44515a;
  font-size: 12.465px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.581px;

  li a {
    border: 0.78px solid #d8d8d8;
    background: white;
    border-radius: 4px;
    padding: 3px 12px;
    cursor: pointer;
  }

  .selected a {
    border: 0.78px solid #44515a;
  }
`;

export default UploadingList;
