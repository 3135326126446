import React from "react";
import Loader from "./Loader";
import {
  CheckIcon,
  PlayIcon,
  PauseIcon,
  ThumbUpIcon,
  ThumbDownIcon,
} from "@heroicons/react/solid";
import { DuplicateIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import styled from "styled-components";
import { observer, inject } from "mobx-react";

import CodeEditor from "@uiw/react-textarea-code-editor";
import TextHighLighter from "./TextHighLighter";

export const Output = inject("store")(
  observer(
    ({
      store,
      title,
      desc,
      Icon,
      output,
      code,
      language,
      outputs,
      loading,
      children,
      fromColor,
      toColor,
      outputsColor,
      OutputsIcon,
      currentPrompt = undefined,
      feedbackId,
      handleFeedbackValue,
      feedbackValue,
      utterances = [],
      entities = {},
      custom_summary = undefined,
    }) => {
      return (
        <div className="relative mb-12">
          <div className=" align-bottom bg-white md:rounded-3xl text-left  shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl">
            <div className=" px-6 py-6">
              <div className="sm:flex sm:items-start">
                {loading ? (
                  <>
                    <Loader active={loading} className="w-10 h-10" />
                  </>
                ) : (
                  <>
                    <div
                      className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${
                        output ? "green" : "gray"
                      }-300 sm:mx-0 sm:h-10 sm:w-10 bg-gradient-to-r from-${
                        fromColor ? fromColor : "green-400"
                      } to-${toColor ? toColor : "blue-500"}`}
                    >
                      {Icon ? (
                        <Icon
                          className={`h-6 w-6 text-white`}
                          aria-hidden="true"
                        />
                      ) : null}
                    </div>
                  </>
                )}

                <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div
                    as="h3"
                    className="text-base leading-6 font-medium text-gray-900"
                  >
                    {title}
                  </div>
                  <p className="text-sm text-gray-500">{desc}</p>
                </div>
              </div>

              {code ? null : output ? (
                <div className="whitespace-pre-wrap min-w-full text-gray-800 h-auto text-sm divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {output ? (
                    currentPrompt === "Audio Transcription" &&
                    Object.entries(entities).length > 0 ? (
                      <TextHighLighter output={output} entities={entities} />
                    ) : (
                      output
                    )
                  ) : null}
                </div>
              ) : null}

              {custom_summary && (
                <>
                  <div className="divide-y divide-dashed divide-gray-300"></div>
                  <div className="whitespace-pre-wrap min-w-full text-gray-800 h-auto text-lg divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    {custom_summary}
                  </div>
                </>
              )}

              {output && outputs && outputs.length ? (
                <div className="divide-y divide-dashed divide-gray-300">
                  {" "}
                  <div></div>
                  <div></div>
                </div>
              ) : null}

              {outputs && outputs.length ? (
                currentPrompt === "Audio Transcription" ? (
                  <OutputSpeakerDiarization
                    outputs={outputs}
                    utterances={utterances}
                    entities={entities}
                  />
                ) : (
                  <Outputs
                    outputs={outputs}
                    outputsColor={outputsColor}
                    OutputsIcon={OutputsIcon}
                  />
                )
              ) : null}

              {code && code.length ? (
                <CodeEditor
                  style={{
                    fontFamily: "JetBrains Mono",
                    fontSize: "1rem",
                  }}
                  padding={30}
                  language={language}
                  value={code}
                />
              ) : null}
              <QuickTools
                outputs={outputs}
                output={output}
                code={code}
                feedbackId={feedbackId}
                handleFeedbackValue={handleFeedbackValue}
                feedbackValue={feedbackValue}
              />
            </div>
          </div>
        </div>
      );
    }
  )
);

export const QuickTools = inject("store")(
  observer(
    ({
      store,
      output,
      outputs,
      code,
      feedbackId,
      handleFeedbackValue,
      feedbackValue,
    }) => {
      const handleRating = async (value, feedbackId) => {
        const feedbackValue = await store.setRating(value, feedbackId);

        handleFeedbackValue(feedbackValue);
      };

      return (
        <>
          {output || code || (outputs && outputs.length) ? (
            <div className="flex">
              <Shortcut
                className="p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative group flex flex-col items-center group text-gray-300"
                onClick={() => store.copyToClipboard(output || code || outputs)}
              >
                <DuplicateIcon className="w-5 h-5" />
                <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                  <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                    Copy text to clipboard
                  </span>
                </Tooltip>
              </Shortcut>

              {feedbackId ? (
                <>
                  <Shortcut
                    className={`p-1 rounded-lg cursor-pointer ${
                      feedbackValue == "good"
                        ? "bg-green-200 text-green-700"
                        : "hover:bg-green-200 hover:text-green-700 text-gray-300"
                    } relative group flex flex-col items-center group`}
                    onClick={() => handleRating("good", feedbackId)}
                  >
                    <ThumbUpIcon className="w-5 h-5" />
                  </Shortcut>

                  <Shortcut
                    className={`p-1 rounded-lg cursor-pointer ${
                      feedbackValue == "bad"
                        ? "bg-red-200 text-red-700"
                        : "hover:bg-red-200 hover:text-red-700 text-gray-300"
                    } relative group flex flex-col items-center group`}
                    onClick={() => handleRating("bad", feedbackId)}
                  >
                    <ThumbDownIcon className="w-5 h-5" />
                  </Shortcut>
                </>
              ) : null}

              <div className="flex-1"></div>
              <Shortcut
                className="p-1 rounded-lg cursor-pointer hover:bg-red-200 hover:text-red-700 relative group flex flex-col items-center group text-gray-300"
                onClick={() =>
                  store.reportToFeedback(output || code || outputs)
                }
              >
                <ExclamationCircleIcon className="w-5 h-5" />
                <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                  <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                    Report issue with output
                  </span>
                </Tooltip>
              </Shortcut>
            </div>
          ) : null}
        </>
      );
    }
  )
);

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;

function Outputs({ outputs, outputsColor, OutputsIcon }) {
  return (
    <div className="whitespace-pre-wrap min-w-full py-4 text-gray-800 h-auto text-sm divide-y">
      {outputs.map((output, index) => (
        <div className="py-2 flex items-start" key={index}>
          <div
            className={`mr-4 flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${
              outputsColor ? outputsColor : "green"
            }-200 text-${outputsColor ? outputsColor : "green"}-600`}
          >
            {OutputsIcon === false ? (
              `${index + 1}`
            ) : OutputsIcon ? (
              <OutputsIcon
                className={`h-4 w-4 text-${
                  outputsColor ? outputsColor : "green"
                }-600`}
                aria-hidden="true"
              />
            ) : (
              <CheckIcon
                className={`h-4 w-4 text-${
                  outputsColor ? outputsColor : "green"
                }-600`}
                aria-hidden="true"
              />
            )}
          </div>
          {output}
        </div>
      ))}
    </div>
  );
}

const OutputSpeakerDiarization = inject("store")(
  observer(({ store, outputs, utterances, entities }) => {
    const { isPlaying, currentIndex } = store.audioStatus;

    const extractTimestamp = async (idx) => {
      if (isPlaying && currentIndex === idx) {
        store.setAudioStatus({ isPlaying: false });
        return;
      }

      const { start, end } = utterances[idx];
      if (isPlaying) {
        store.setAudioStatus({ isPlaying: false });
      }

      store.setAudioTimeStamp(start, end);
      store.setAudioStatus({ isPlaying: true, currentIndex: idx });
    };

    // const handleTextHighlight = (idx, str) => {
    // 	if (!str) return
    // 	const utterance = utterances[idx]?.words;
    // 	const selectedWords = str.trim().split(' ');
    // 	let firstIndex, lastIndex;

    // 	// utterance.forEach((word) => {
    // 	// 	if (selectedWords.includes(word.text)) {
    // 	// 		if (!firstIndex) {
    // 	// 			firstIndex = utterance.map(w => w.text).indexOf(word.text);
    // 	// 			break;
    // 	// 		}
    // 	// 		lastIndex = utterance.map(w => w.text).indexOf(word.text);
    // 	// 	}
    // 	// })
    // 	console.log(selectedWords)
    // 	utterance.some((word, i) => {
    // 		if (selectedWords.includes(word.text)) {
    // 			if (!firstIndex) {
    // 				firstIndex = i;
    // 				lastIndex = selectedWords.length - 1;
    // 				return true;
    // 			}
    // 		}
    // 	})

    // 	if (utterance[lastIndex] !== selectedWords[selectedWords.length -1]) lastIndex += 1;
    // 	if (utterance[firstIndex] !== selectedWords[0]) firstIndex -= 1;

    // 	store.setAudioTimeStamp(utterance[firstIndex].start, utterance[lastIndex].end)

    // }

    return (
      <div className="whitespace-pre-wrap min-w-full py-4 text-gray-800 h-auto text-sm divide-y">
        {outputs.map((output, index) => (
          <div className="py-2 flex items-start" key={index}>
            <div
              className={`mr-4 flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full cursor-pointer`}
              onClick={() => extractTimestamp(index)}
            >
              {currentIndex === index && isPlaying ? (
                <PauseIcon className="h-8 w-8" aria-hidden="true" />
              ) : (
                <PlayIcon className="h-8 w-8" aria-hidden="true" />
              )}
            </div>
            <span
            // onMouseUpCapture={() => handleTextHighlight(index, window.getSelection().toString())}
            >
              <TextHighLighter output={output} entities={entities} />
            </span>
          </div>
        ))}
      </div>
    );
  })
);

export default Output;
