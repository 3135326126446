import React, { useState, useRef, useEffect } from "react";
import { PlayIcon } from "@heroicons/react/outline";

const TutorialButton = ({ videos, onVideoSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleVideoClick = (video) => {
    onVideoSelect(video);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="flex items-center text-lg py-3 px-6 lg:py-4 lg:px-8 my-2 ml-2 cursor-pointer  hover:bg-navix-turquoise rounded-md font-medium transition items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <PlayIcon className="h-5 w-5 mr-2" />
        <span className="hidden lg:block">Tutorial</span>
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-96 bg-white rounded-md shadow-lg z-10 max-h-80 overflow-y-auto">
          {videos.map((video, index) => (
            <div
              key={video.id}
              className="flex items-center p-3 hover:bg-indigo-50 cursor-pointer transition-colors duration-200"
              onClick={() => handleVideoClick(video)}
            >
              <img
                src={`https://img.youtube.com/vi/${video.id}/default.jpg`}
                alt={video.title}
                className="w-20 h-15 object-cover rounded mr-3"
              />
              <span className="text-sm font-medium">{video.title}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TutorialButton;
