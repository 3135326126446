import { useRef, useState } from "react";
import ReactQuill from "react-quill";
import { markdownToHtml, htmlToMarkdown } from "../utils/parser";

const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike", "blockquote", "link"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  ["clean"],
];

export default function Editor(props) {
  const [value, setValue] = useState(markdownToHtml(props.value || ""));
  const reactQuillRef = useRef(null);

  const onChange = (content) => {
    setValue(content);

    if (props.onChange) {
      props.onChange({
        html: content,
        markdown: htmlToMarkdown(content),
      });
    }
  };

  return (
    <ReactQuill
      ref={reactQuillRef}
      placeholder="Start writing..."
      modules={{
        toolbar: {
          container: TOOLBAR_OPTIONS,
        },
      }}
      value={value}
      onChange={onChange}
    />
  );
}
