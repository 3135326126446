import React from "react";
import styled from "styled-components";
import { XIcon } from "@heroicons/react/solid";
import CustomTemplateForm from "../../ClinicalNoteWriter/components/CustomTemplateForm";

const CustomTemplateModal = ({
  onClose,
  onTemplateCreated,
  clearUploadedFile,
}) => {
  return (
    <ModalOverlay>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <XIcon className="icon" />
        </CloseButton>
        <CustomTemplateForm
          isModal={true}
          onClose={onClose}
          onTemplateCreated={onTemplateCreated}
          clearUploadedFile={clearUploadedFile}
        />
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  border-radius: 50%;

  .icon {
    width: 20px;
    height: 20px;
    color: #666;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export default CustomTemplateModal;
