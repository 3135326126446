import React from "react";

const Button = ({ isActive, text, onClick }) => {
  let buttonClass = `px-3 sm:px-7 navix-scribe-accordion-button`;
  buttonClass += isActive ? "--active" : "";

  return (
    <button className={buttonClass} onClick={onClick}>
      {text}
    </button>
  );
};

const ActiveArchivedToggle = ({ activeButton, setActiveButton }) => {
  return (
    <div className="bg-white flex flex-row w-min p-0.5 sm:p-1 my-2 rounded-md border border-[#7D7D7D]">
      <Button
        isActive={activeButton === "active"}
        text="Active"
        onClick={() => setActiveButton("active")}
      />
      <Button
        isActive={activeButton === "archived"}
        text="Archived"
        onClick={() => setActiveButton("archived")}
      />
    </div>
  );
};

export default ActiveArchivedToggle;
