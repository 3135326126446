import React, { Component } from "react";
import {
  BanIcon,
  DatabaseIcon,
  PencilIcon,
  MenuAlt1Icon,
  LightBulbIcon,
  SparklesIcon,
  UserCircleIcon,
} from "@heroicons/react/solid";
import Logo from "./Logo";
import config from "./config";
import { observer, inject } from "mobx-react";

@inject("store")
@observer
class Pricing extends Component {
  render() {
    return (
      <>
        {this.props.store.profile.status ? null : (
          <div className="border-b border-gray-300 bg-white shadow-sm ">
            <div className="container mx-auto px-4 md:px-28 flex select-none">
              <div className="relative text-gray-400 focus-within:text-green-500 flex flex-1 "></div>
              <div
                onClick={this.props.store.handleLogout}
                className="cursor-pointer text-lg flex py-3 px-6 xl:py-4 xl:px-8 hover:bg-gray-100 rounded-t-md font-medium transition items-center"
              >
                <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
                <div className="hidden lg:block">Log Out</div>
              </div>
            </div>
          </div>
        )}

        <div className="container mx-auto px-8 py-4 lg:px-28 lg:py-12 lg:pb-64 select-none">
          {this.props.store.profile.status ? null : (
            <>
              <div className="mx-auto">
                <Logo className="w-32 h-32 inline-block" />
              </div>

              <h2 className="text-xl sm:text-2xl md:text-5xl text-gray-700 text-center">
                Start now
              </h2>

              <p className="text-lg sm:text-xl md:text-2xl text-gray-400 mb-14 mt-2 text-center">
                Pick a pricing option to begin using NavixAI
              </p>
            </>
          )}

          <Grid>
            {!this.props.store.profile.plan && (
              <Price
                store={this.props.store}
                fromColor="gray-400"
                toColor="gray-500"
                title="Free"
                price={14.99}
                description="Free for 14 days, then $14.99"
                credits={250}
                words="15,000"
                letters="60,000"
              />
            )}

            {this.props.store.profile.plan !== "entry" && (
              <Price
                store={this.props.store}
                fromColor="green-400"
                toColor="green-600"
                title="Entry"
                price={14.99}
                description="Get access to all our AI-powered features."
                credits={250}
                words="15,000"
                letters="60,000"
              />
            )}

            {this.props.store.profile.plan !== "pro" && (
              <Price
                store={this.props.store}
                fromColor="indigo-500"
                toColor="red-500"
                title="Pro"
                price={58.99}
                description="Get access to all our AI-powered features."
                credits={750}
                words="45,000"
                letters="180,000"
              />
            )}
          </Grid>
        </div>
      </>
    );
  }
}

const Price = ({
  store,
  fromColor,
  toColor,
  title,
  price,
  description,
  credits,
  words,
  letters,
}) => (
  <div className="flex relative">
    <div
      className={`absolute inset-0 bg-gradient-to-r from-${
        fromColor ? fromColor : "green-400"
      } to-${
        toColor ? toColor : "blue-500"
      } shadow-lg transform skew-y-0 -rotate-3 rounded-3xl`}
    ></div>

    <div
      className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 border-t-2 hover:border-${
        fromColor ? fromColor : "blue-400"
      } md:flex relative transform hover:scale-105 hover:text-black flex-1`}
    >
      <div className="p-8 flex-1">
        <div
          href="#"
          className={`text-${
            fromColor ? fromColor : "green-500"
          } block text-2xl leading-tight font-medium mb-2`}
        >
          {title}
        </div>

        <div className="text-4xl text-black font-bold">
          ${price}
          {title != "Add-on" && (
            <span className="text-lg text-gray-400"> / month</span>
          )}
        </div>

        <p className="mt-4 text-lg">{description}</p>

        <div className="divide-y divide-dashed divide-gray-300 mt-4 text-base">
          {/* Temporarily hide credits display */}
          {/* <div className="py-2 flex items-center">
            <DatabaseIcon
              className={`w-6 h-6 mr-2 text-${
                fromColor ? fromColor : "green-500"
              }`}
            />

            <div>
              <span className="font-medium text-black">{credits}</span> x
              Credits
            </div>
          </div> */}

          {words && (
            <div className="py-2 flex items-center">
              <MenuAlt1Icon
                className={`w-6 h-6 mr-2 text-${
                  fromColor ? fromColor : "green-500"
                }`}
              />

              <div>
                <span className="font-medium text-black">{words}</span> x Words
              </div>
            </div>
          )}

          {letters && (
            <div className="py-2 flex items-center">
              <PencilIcon
                className={`w-6 h-6 mr-2 text-${
                  fromColor ? fromColor : "green-500"
                }`}
              />

              <div>
                <span className="font-medium text-black">{letters}</span> x
                Letters
              </div>
            </div>
          )}

          {title != "Add-on" && (
            <>
              {title != "Pro" ? (
                <>
                  <div className="py-2 flex items-center">
                    <BanIcon className="w-6 h-6 mr-2 text-gray-400" />

                    <div>
                      <span className="text-gray-400">Access to all tools</span>
                    </div>
                  </div>

                  <div className="py-2 flex items-center">
                    <BanIcon className="w-6 h-6 mr-2 text-gray-400" />

                    <div>
                      <span className="text-gray-400">
                        {title == "Free"
                          ? "New beta-feature testing"
                          : "Early access to beta tools"}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="py-2 flex items-center">
                    <SparklesIcon
                      className={`w-6 h-6 mr-2 text-${
                        fromColor ? fromColor : "green-500"
                      }`}
                    />

                    <div>
                      <span className="font-medium text-black">
                        Access to all tools
                      </span>
                    </div>
                  </div>

                  <div className="py-2 flex items-center">
                    <LightBulbIcon
                      className={`w-6 h-6 mr-2 text-${
                        fromColor ? fromColor : "green-500"
                      }`}
                    />

                    <div>
                      <span className="font-medium text-black">
                        Early access to beta tools
                      </span>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <form
          action={
            store.baseURL +
            (title != "Add-on"
              ? "user/stripe/subscribe"
              : "user/stripe/credits")
          }
          method="POST"
          className="flex flex-1"
        >
          <input
            type="hidden"
            name="token"
            value={store.api.defaults.headers.common["x-access-token"]}
          />
          <input type="hidden" name="plan" value={title} />

          <button
            type="submit"
            className={`mt-8 inset-0 bg-gradient-to-r from-${
              fromColor ? fromColor : "green-400"
            } to-${
              toColor ? toColor : "blue-500"
            } shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
          >
            Get Started
          </button>
        </form>
      </div>
    </div>
  </div>
);

const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-12 mt-4 xl:grid-cols-3 ">{children}</div>
);

export default Pricing;
