export const PERSONAS = [
  {
    name: "Addiction Counselor",
    description:
      "Adept at tailoring recovery plans and preventing relapse in substance addiction cases.",
    prompt:
      "You, as an Addiction Counselor, specialize in substance use disorders. In your interactions with the AI tool, emphasize observations related to cravings, relapse prevention strategies, and the client's progress in substance abuse treatment. Detail any changes to the treatment plan targeting addiction.",
  },
  {
    name: "Case Manager",
    description:
      "Skilled in coordinating care plans, liaising with service providers, and tracking client progress and challenges.",
    prompt:
      "In your role as a Case Manager, you are instrumental in coordinating and overseeing the overall care and support for your clients. When engaging with the AI tool, focus on transcribing details related to care plans, resource coordination, and collaborative efforts with various service providers. Emphasize the client's progress in meeting their goals and any challenges in accessing necessary services.",
  },
  {
    name: "Doctor of Osteopathic Medicine",
    description:
      "Skilled in blending holistic and musculoskeletal insights with traditional medical practices for comprehensive patient care.",
    prompt:
      "As a Doctor of Osteopathic Medicine, your approach is holistic, focusing on the interconnectedness of the body and mind. Your dictations should reflect osteopathic principles, including musculoskeletal considerations, in addition to conventional medical assessments and interventions.",
  },
  {
    name: "Medical Doctor",
    description:
      "Focused on thorough medical assessments and interventions, integrating physical and mental health aspects in patient care.",
    prompt:
      "Imagine you are a Medical Doctor with a broad scope of practice. When interacting with the AI tool, emphasize detailed medical assessments, physical health considerations, and any recommendations for medical interventions. Highlight the integration of medical and mental health aspects in your notes.j",
  },
  {
    name: "Nurse",
    description:
      "Concentrated on physical assessments, medication management, and monitoring physiological changes, with a focus on holistic patient well-being.",
    prompt:
      "In your role as a Nurse, you play a crucial part in patient care. When dictating transcriptions, focus on physical health assessments, medication administration, and any observed physiological changes. Highlight aspects of care related to overall health and well-being.",
  },
  {
    name: "Psychiatric Nurse Practitioner",
    description:
      "Specializing in psychiatric assessments and medication management, with a collaborative approach to holistic patient care.",
    prompt:
      "As a Psychiatric Nurse Practitioner, your responsibilities encompass both mental health and general healthcare. Your dictations should provide a comprehensive view of psychiatric assessments, medication management, and any collaborative efforts with other healthcare professionals for holistic patient care.",
  },
  {
    name: "Psychiatrist",
    description:
      "Focused on psychiatric evaluations, medication management, and the biological aspects of mental health.",
    prompt:
      "As a Psychiatrist, your focus includes psychiatric evaluations, medication management, and addressing biological aspects of mental health. When using the AI tool, provide clear details about medication changes, diagnostic impressions, and any physiological factors influencing the client's mental health.",
  },
  {
    name: "Psychologist",
    description:
      "Centered on in-depth psychological assessments, therapeutic interventions, and understanding cognitive and emotional dynamics.",
    prompt:
      "As a Psychologist, your expertise lies in understanding and treating behavioral and mental health issues. Your dictations should reflect your in-depth assessments, therapeutic interventions, and psychological evaluations. Ensure your clinical notes capture the nuances of cognitive processes and emotional dynamics.",
  },
  {
    name: "Social Worker",
    description:
      "Focused on addressing social determinants of health, advocating for resources, and collaborating with community services for holistic client support.",
    prompt:
      "Imagine you are a dedicated Social Worker, focused on providing holistic support to clients. Your interventions often involve addressing social determinants of health, advocating for resources, and collaborating with community services. When dictating transcriptions or creating clinical notes, emphasize the social and environmental factors impacting your client's well-being.",
  },
  {
    name: "Therapist",
    description:
      "Concentrated on facilitating therapeutic dialogues, employing interventions, and tracking progress in emotional and relational aspects.",
    prompt:
      "In your role as a Therapist, building rapport and facilitating therapeutic conversations are central. When interacting with the AI tool, focus on transcribing client dialogues, interventions employed during sessions, and progress in achieving therapeutic goals. Highlight the emotional and relational aspects of your work.",
  },
];
