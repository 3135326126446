import {
  ArchiveIcon,
  CheckCircleIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import dayjs from "dayjs";
import styled, { css } from "styled-components";

const HistoryList = ({
  activeButton,
  currentItems,
  handleEditTitle,
  handleShowModal,
  editing,
  historyId,
  historyTitle,
  handleTitleChange,
  handleTitleKeyDownAction,
  handleUpdateTitle,
  handleCancelEdit,
  handleClickActiveRecord,
  outputActiveRecord,
  inputActiveRecord,
}) => {
  if (currentItems.length === 0) {
    return "No records at the moment.";
  }

  return currentItems.map((i) => (
    <ListItem
      key={i._id}
      onClick={() => handleClickActiveRecord(i._id)}
      active={
        outputActiveRecord?._id === i._id || inputActiveRecord?._id === i._id
      }
      className="cursor-pointer flex-col gap-1 sm:flex-row"
    >
      {editing && historyId === i._id ? (
        <>
          <input
            type="text"
            value={historyTitle}
            onChange={handleTitleChange}
            onKeyDown={handleTitleKeyDownAction}
            className="text-xl flex-1 border-b border-gray-500"
            onClick={(e) => e.stopPropagation()}
          />

          <div className="flex items-center">
            <CheckCircleIcon
              className={`text-green-700 h-5 w-5 cursor-pointer`}
              onClick={(e) => {
                handleUpdateTitle();
                e.stopPropagation();
              }}
            />

            <XCircleIcon
              className={`text-red-700 h-5 w-5 cursor-pointer`}
              onClick={(e) => {
                handleCancelEdit();
                e.stopPropagation();
              }}
            />
          </div>
        </>
      ) : (
        <>
          <h1 className="flex-1 truncate font-semibold w-full sm:w-1/3">
            {i.title}
          </h1>

          <div className="flex justify-between gap-1">
            <div className="flex flex-col gap-1">
              {i.subToolAction && (
                <span className="border border-green-500 hidden sm:block bg-green-300 rounded-full py-1 px-2 text-xs capitalize">
                  {i.subToolAction}
                </span>
              )}

              {i.audioTranscriber?.status && (
                <span
                  className={`border border-${
                    i.audioTranscriber.status === "done" ? "green" : "red"
                  }-500 bg-${
                    i.audioTranscriber.status === "done" ? "green" : "red"
                  }-300 rounded-full py-1 px-2 text-xs capitalize`}
                >
                  {i.audioTranscriber?.status}
                </span>
              )}
            </div>

            <div className="flex flex-col w-20 text-gray-500">
              <span>{dayjs(i.created).format("MM-DD-YYYY")}</span>
              <span>{dayjs(i.created).format("hh:mm A")}</span>
            </div>

            <div className="flex items-center w-20 gap-1  text-gray-500">
              <PencilIcon
                className="w-5 h-5 rounded border border-gray-500 cursor-pointer"
                onClick={(e) => {
                  handleEditTitle(i._id, i.title);
                  e.stopPropagation();
                }}
              />

              <ArchiveIcon
                className="w-5 h-5 rounded border border-gray-500 cursor-pointer"
                onClick={(e) => {
                  handleShowModal(
                    i._id,
                    activeButton === "active" ? "archive" : "unarchive"
                  );
                  e.stopPropagation();
                }}
              />

              <TrashIcon
                className="w-5 h-5 rounded border border-gray-500 cursor-pointer"
                onClick={(e) => {
                  handleShowModal(i._id, "delete");
                  e.stopPropagation();
                }}
              />
            </div>
          </div>
        </>
      )}
    </ListItem>
  ));
};

const ListItem = styled.div`
  display: flex;
  background: #8b23ef1c;
  padding: 0.5rem 0.5rem;
  border-radius: 6px;
  ${(props) =>
    props.active
      ? css`
          border: 2px solid #8b23ef;
        `
      : null};
`;

export default HistoryList;
