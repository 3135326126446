import { inject, observer } from "mobx-react";
import { runInAction } from "mobx";
import { useState, useEffect, useRef } from "react";
import WaveSurferRecorder from "../../../components/WaveSurferRecorderV2";
import toast from "react-hot-toast";
import Loader from "../../../components/Loader";
import styled from "styled-components";
import { UploadIcon } from "@heroicons/react/outline";
import {
  transcribeAudio,
  checkAccountStatus,
  updateHistory,
  waitForTranscription,
} from "../api.service.v2";
import { v4 as uuid } from "uuid";
import { formatDuration } from "../../../tools/audio/audioUtils";
import { createThread, addMessage } from "../azure-api-service";
import TranscriptionLoader from "./TranscriptionLoader";

const AUDIO_ACCEPT_TYPES = [
  "audio/mpeg",
  "audio/mp3",
  "audio/wav",
  "audio/aac",
  "audio/flac",
  "audio/ogg",
  "audio/x-ms-wma",
  "audio/x-m4a",
  "audio/aiff",
  "audio/x-aac",
  "audio/mp4",
  "audio/vnd.dlna.adts",
];

const RecordingComponent = inject(
  "navixScribeV2Store",
  "store"
)(
  observer(({ navixScribeV2Store, store, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const audioComponentRef = useRef(null);

    useEffect(() => {
      if (navixScribeV2Store.inputActiveRecord.subToolAction === "audio") {
        if (navixScribeV2Store.inputActiveRecord.audioTranscriber) {
          setAudioUrl(
            navixScribeV2Store.inputActiveRecord.audioTranscriber?.aws_url
          );
        }
      }
    }, [navixScribeV2Store.inputActiveRecord]);

    const clearAudioChunks = () => {
      const current = audioComponentRef.current;
      if (current) {
        current.clearAudioChunks();
      }
    };

    const handleRecordingStateChange = (isRecording) => {
      setIsRecording(isRecording);
      setLoading(isRecording);
      if (!isRecording) {
        const {
          inputForm: { file },
        } = navixScribeV2Store;
        if (file) {
          const url = URL.createObjectURL(file);
          setAudioUrl(url);
        }
      }
    };

    const handleAudioDuration = (duration) => {
      setAudioDuration(duration);
      navixScribeV2Store.setInputForm("audioDuration", Math.floor(duration));
      navixScribeV2Store.setInputForm(
        "audioDurationString",
        formatDuration(Math.floor(duration))
      );
    };

    const handleTranscribe = async () => {
      const {
        inputForm: { file },
        setInputForm,
        setActiveHistory,
        setTriggerSection,
      } = navixScribeV2Store;

      clearAudioChunks();

      try {
        setIsTranscribing(true);
        const result = await checkAccountStatus();
        if (!result.success) {
          toast.error(result.message);
          return;
        }

        setLoading(true);

        const commonPayload = {
          _id: uuid(),
          title: `AUDIO ${navixScribeV2Store.inputUploads.length + 1}`,
          created: new Date(),
          toolType: "NavixScribe",
          toolAction: "Input",
          subToolAction: "audio",
          isDeleted: false,
          isArchived: false,
          favorite: false,
          forgotten: false,
        };

        navixScribeV2Store.setInputUploads([
          {
            ...commonPayload,
            audioTranscriber: {
              status: "processing",
            },
          },
          ...navixScribeV2Store.inputUploads,
        ]);
        navixScribeV2Store.setInputForm("inputText", "");

        const processAudioForTranscription = async (audioData) => {
          const duration = Math.floor(
            navixScribeV2Store.savedDuration / 1000 - 1
          );
          const durationString = formatDuration(duration);
          const result = await transcribeAudio(file, duration, durationString);

          setInputForm("audioDuration", duration);
          setInputForm("audioDurationString", durationString);
          toast.success(result.data.message, { duration: 5000 });

          if (result.data.history && result.data.history._id) {
            setActiveHistory(result.data.history._id, "Input");

            const completedHistory = await waitForTranscription(
              result.data.history._id
            );

            let threadId = navixScribeV2Store.selectedThread;

            if (!threadId) {
              console.log("No existing thread, updating makeAwsUrlAvailable");
              try {
                const updateResult = await updateHistory(
                  result.data.history._id,
                  {
                    makeAwsUrlAvailable: true,
                  }
                );
                console.log("Update result:", updateResult);
              } catch (error) {
                console.error("Error updating makeAwsUrlAvailable:", error);
              }

              console.log("Creating new thread");
              const newThread = await createThread();
              threadId = newThread.id;

              console.log("Updating history with new threadId");
              try {
                const threadUpdateResult = await updateHistory(
                  result.data.history._id,
                  {
                    threadId: threadId,
                  }
                );
                console.log("Thread update result:", threadUpdateResult);
              } catch (error) {
                console.error("Error updating threadId:", error);
              }
            }

            navixScribeV2Store.setSelectedThread(threadId);

            const transcriptionText = completedHistory.outputs.join(" ");
            if (transcriptionText.trim() !== "") {
              await addMessage(threadId, "user", transcriptionText, false);

              await navixScribeV2Store.fetchThreadMessages(threadId);
            } else {
              console.warn(
                "Transcription text is empty. Skipping message addition."
              );
            }
          }
        };

        if (file && file instanceof Blob) {
          const audioData = await file.arrayBuffer();
          await processAudioForTranscription(audioData);
        } else if (audioUrl) {
          const response = await fetch(audioUrl);
          const audioData = await response.arrayBuffer();
          await processAudioForTranscription(audioData);
        } else {
          throw new Error("No valid audio source found");
        }
      } catch (err) {
        console.error("Error in handleTranscribe:", err);
        toast.error("Error processing audio: " + err.message);
      } finally {
        setIsTranscribing(false);
        setLoading(false);
        setTriggerSection("Input");
        if (typeof onClose === "function") {
          onClose();
        }
      }
    };

    const handleFileChangeEvent = async (e) => {
      const file = e.target.files[0];
      const { setInputForm } = navixScribeV2Store;
      if (!file) return;

      try {
        const MAX_FILE_SIZE_MB = 100;
        const sizeInMb = file.size / 1024 / 1024;
        if (sizeInMb > MAX_FILE_SIZE_MB) {
          toast.error("File size must be less than or equal to 100MB");
          return;
        }

        if (AUDIO_ACCEPT_TYPES.includes(file.type)) {
          const url = URL.createObjectURL(file);
          setAudioUrl(url);
          setUploadedFile(file);
          setInputForm("file", file);
          setInputForm("filename", file.name);
        } else {
          throw new Error("Unsupported file type");
        }
      } catch (err) {
        toast.error("Error processing file: " + err.message);
        setInputForm("file", null);
        setInputForm("filename", "");
        setUploadedFile(null);
      }
    };

    const isGenerateButtonDisabled = () => {
      const {
        inputForm: { file },
      } = navixScribeV2Store;
      return !file || loading;
    };

    return (
      <OuterContainer>
        <RecorderContainer>
          {isTranscribing ? (
            <TranscriptionLoader />
          ) : (
            <>
              <WaveSurferRecorder
                ref={audioComponentRef}
                onAudioUrl={(e) => setAudioUrl(e)}
                onBlob={(e) => {
                  navixScribeV2Store.setInputForm("file", e);
                  navixScribeV2Store.setInputForm("filename", "");
                }}
                recording={handleRecordingStateChange}
                isRecording={isRecording}
                uploadedFile={uploadedFile}
              />
              <ButtonContainer>
                <UploadButton htmlFor="file-upload">
                  <UploadIcon className="w-6 h-6" />
                  Upload Audio File
                </UploadButton>
                <input
                  type="file"
                  id="file-upload"
                  className="hidden"
                  accept={AUDIO_ACCEPT_TYPES.join(",")}
                  onChange={handleFileChangeEvent}
                />
                <TranscribeButton
                  onClick={handleTranscribe}
                  disabled={isGenerateButtonDisabled()}
                >
                  <Loader active={loading} className="w-6 h-6 mr-1" />
                  Transcribe
                </TranscribeButton>
              </ButtonContainer>
            </>
          )}
        </RecorderContainer>
      </OuterContainer>
    );
  })
);

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 10px;
  background: #ffffff;
  width: 780px;
  border: 1px solid #d1d1d2; /* Add the border here */
`;

const RecorderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  height: 300px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Adjust this for spacing between buttons */
  gap: 1rem; /* Adjust the gap between buttons if necessary */
  width: 100%;
  margin-top: 1rem;
`;

const UploadButton = styled.label`
  background: #f3f4f6;
  color: #000000;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
`;

const TranscribeButton = styled.button`
  background-color: #4c1d95;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 20px;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  transition: background 0.3s;

  &:disabled {
    background: #b0a0c9;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: #b39ddb;
  }

  &:active:not(:disabled) {
    background: #9575cd;
  }
`;

export default RecordingComponent;
