// audioUtils.js

/**
 * Convert elapsed time in milliseconds to mm:ss format.
 * @param {number} elapsedTime - Elapsed time in milliseconds.
 * @returns {string} - Formatted time in mm:ss.
 */
const formatElapsedTime = (elapsedTime) => {
  const minutes = Math.floor(elapsedTime / 60000);
  const seconds = Math.floor((elapsedTime % 60000) / 1000);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

/**
 * Convert duration in seconds to mm:ss format.
 * @param {number} durationInSeconds - Duration in seconds.
 * @returns {string} - Formatted duration in mm:ss.
 */
const formatDuration = (durationInSeconds) => {
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = Math.floor(durationInSeconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

async function convertAudioFromUrl(audioUrl, store) {
  try {
    const response = await store.api.post("/audio/convert-audio-from-url", {
      audioUrl,
    });
    return response.data.url;
  } catch (error) {
    console.error("Error processing audio URL:", error);
    throw error;
  }
}

module.exports = {
  formatElapsedTime,
  formatDuration,
  convertAudioFromUrl,
};
