const appType = () => {
  const env = process.env.NODE_ENV;
  const APP_TYPE = process.env.REACT_APP_TYPE;

  if (APP_TYPE) {
    return APP_TYPE;
  } else {
    let hub_urls = process.env.REACT_APP_HUB_URLS;
    const location = window.location;
    const protocol = location.protocol;
    const host = location.host;
    const url = `${protocol}//${host}/`;
    let app = "main";

    switch (env) {
      case "development":
        switch (url) {
          case "http://localhost:3001/":
            app = "hub";
            break;
        }
        break;
      default:
        if (hub_urls) {
          hub_urls = hub_urls.split(",");

          if (hub_urls.includes(url)) {
            app = "hub";
          }
        }
    }

    return app;
  }
};

export const getAppType = appType();
