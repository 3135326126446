import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import Modal from "./Modal";
import {
  BookOpenIcon,
  CheckCircleIcon,
  PencilIcon,
  XCircleIcon,
  TrashIcon,
} from "@heroicons/react/outline";

export const deleteHistory = async (store, id) => {
  try {
    await store.api.delete(`/histories/${id}`);
    return true;
  } catch (err) {
    console.error("Error deleting history:", err);
    return false;
  }
};

export const History = inject("store")(
  observer(({ store, endpoint, newRequest }) => {
    const observerTarget = useRef(null);
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);

    const [editing, setEditing] = useState(false);
    const [historyId, setHistoryId] = useState(null);
    const [historyTitle, setHistoryTitle] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const history = useHistory();

    const navigate = (_endpoint, _id) => {
      if (!editing) {
        history.push(`${_endpoint}?tool=${_id}`);
      }
    };

    useEffect(() => {
      if (Object.keys(newRequest).length !== 0) {
        setItems((prevItems) => [newRequest, ...prevItems]);
      }
    }, [newRequest]);

    useEffect(() => {
      fetchHistories();
    }, []);

    const handleEdit = (id, title) => {
      setEditing(true);
      setHistoryId(id);
      setHistoryTitle(title);
    };

    const handleCancelEdit = () => {
      setEditing(false);
      setHistoryId(null);
      setHistoryTitle("");
    };

    const handleSaveEdit = async () => {
      try {
        await store.api.put(`/histories/${historyId}`, {
          title: historyTitle,
        });
        const histories = items.map((i) => {
          if (i._id === historyId) i.title = historyTitle;
          return i;
        });
        setItems(histories);
        toast.success("Successfully updated history title.");
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        handleCancelEdit();
      }
    };

    const handleDelete = async (id) => {
      try {
        const success = await deleteHistory(store, id);
        if (success) {
          setItems((prevItems) => prevItems.filter((i) => i._id !== id));
          toast.success("Successfully removed history.");
          setIsModalOpen(false);
        } else {
          toast.error("Failed to remove history.");
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        handleCancelEdit();
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "Enter") handleSaveEdit();
      if (e.key === "Escape") handleCancelEdit();
    };

    const handleTitleChange = (e) => {
      setHistoryTitle(e.target.value);
    };

    const handleScroll = (e) => {
      const element = e.target;
      if (element.scrollHeight - element.scrollTop === element.clientHeight)
        fetchHistories();
    };

    const fetchHistories = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await store.api.get(
          `/histories?t=${endpoint}&page=${page}`
        );
        if (data.result.length === 0) setIsLastPage(true);
        setItems((prevItems) => [...prevItems, ...data.result]);
        setPage((prevPage) => prevPage + 1);
      } catch (error) {
        setError(error);
        toast.error("Can't get histories data.");
      } finally {
        setIsLoading(false);
      }
    };

    const truncatedText = (text) => text.slice(0, 500);

    const removePlaceholders = (str) => {
      const regex = /{{.*?}}/g;
      return str.replace(regex, "");
    };

    const getFormattedDate = (date) => moment(date).format("MMMM D, YYYY");
    const getFormattedTime = (time) => moment(time).format("h:mm A");

    return (
      <>
        <HistoryLayout>
          <HistoryHeader />
          <ul
            className="overflow-auto"
            style={{ height: "55vh" }}
            onScroll={isLastPage ? null : handleScroll}
          >
            {items.length > 0 &&
              items.map(({ _id, title, created, output, outputs }) => (
                <li
                  key={_id}
                  className="group border p-3 m-1 rounded-lg hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    if (!editing) {
                      navigate(endpoint, _id);
                    }
                  }}
                >
                  <div className="flex gap-2 justify-between align-middle items-center">
                    {editing && historyId === _id ? (
                      <input
                        type="text"
                        value={historyTitle}
                        onChange={handleTitleChange}
                        onKeyDown={handleKeyDown}
                        className="text-xl flex-1 border-b border-gray-500"
                      />
                    ) : (
                      <h1 className="text-xl truncate">{title}</h1>
                    )}
                    <div
                      className="group-hover:opacity-100 opacity-0"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {editing && historyId === _id ? (
                        <div className="flex gap-2">
                          <CheckCircleIcon
                            className={`text-green-700 h-6 w-6`}
                            onClick={handleSaveEdit}
                          />
                          <XCircleIcon
                            className={`text-red-700 h-6 w-6`}
                            onClick={handleCancelEdit}
                          />
                        </div>
                      ) : (
                        <div className="flex gap-2">
                          <PencilIcon
                            className={`text-gray-700 h-5 w-5`}
                            onClick={(e) => {
                              handleEdit(_id, title);
                            }}
                          />
                          <TrashIcon
                            className="h-5 w-5 text-red-500"
                            onClick={(e) => {
                              setIsModalOpen(true);
                              setHistoryId(_id);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <span>{getFormattedDate(created)}</span>
                    <span>{getFormattedTime(created)}</span>
                  </div>
                  <div className="truncate pt-3">
                    {removePlaceholders(truncatedText(outputs?.join(" ")))}
                  </div>
                </li>
              ))}
            {isLoading && <HistorySkeletonLoader />}
          </ul>
          <div ref={observerTarget}></div>
        </HistoryLayout>
        <HistoryModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          historyId={historyId}
          handleDelete={handleDelete}
        />
      </>
    );
  })
);

function HistoryLayout({ children }) {
  return (
    <div className="relative mb-12">
      <div className=" align-bottom bg-white md:rounded-3xl text-left shadow-xl transform transition-all sm:align-middle hover:shadow-2xl focus:shadow-2xl">
        <div className=" px-6 py-6">{children}</div>
      </div>
    </div>
  );
}

function HistoryHeader({
  title = "Histories",
  description = "View your history",
}) {
  return (
    <div className="sm:flex sm:items-start">
      <div
        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-300 sm:mx-0 sm:h-10 sm:w-10 bg-gradient-to-r from-gray-500 to-navix-turquoise`}
      >
        <BookOpenIcon className={`h-6 w-6 text-white`} />
      </div>

      <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
        <div as="h3" className="text-lg leading-6 font-medium text-gray-900">
          {title}
        </div>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
    </div>
  );
}

function HistorySkeletonLoader() {
  return (
    <>
      <li className="animate-pulse flex space-x-4 ">
        <div className=" bg-gray-200 h-16 w-full rounded-lg p-3 m-1"></div>
      </li>
      <li className="animate-pulse flex space-x-4 ">
        <div className=" bg-gray-200 h-16 w-full rounded-lg p-3 m-1"></div>
      </li>
      <li className="animate-pulse flex space-x-4 ">
        <div className=" bg-gray-200 h-16 w-full rounded-lg p-3 m-1"></div>
      </li>
      <li className="animate-pulse flex space-x-4 ">
        <div className=" bg-gray-200 h-16 w-full rounded-lg p-3 m-1"></div>
      </li>
      <li className="animate-pulse flex space-x-4 ">
        <div className=" bg-gray-200 h-16 w-full rounded-lg p-3 m-1"></div>
      </li>
      <li className="animate-pulse flex space-x-4 ">
        <div className=" bg-gray-200 h-16 w-full rounded-lg p-3 m-1"></div>
      </li>
    </>
  );
}

function HistoryModal({
  isModalOpen,
  setIsModalOpen,
  handleDelete,
  historyId,
}) {
  return (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <div className="text-center w-96">
        <TrashIcon className="w-16 mx-auto text-red-500" />
        <div className="mx-auto my-4 w-96">
          <h3 className="text-lg font-block text-gray-800">Confirm Remove</h3>
          <p className="text-sm text-gray-500">
            Are you sure you want to remove this history?
          </p>
        </div>
        <div className="flex gap-4">
          <button
            type="button"
            className="w-full hover:text-white text-gray-500 border border-red-200 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-lg text-sm px-5 py-2.5 text-center"
            onClick={() => handleDelete(historyId)}
          >
            Yes, I'm sure
          </button>
          <button
            type="button"
            className="w-full text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            onClick={() => setIsModalOpen(false)}
          >
            No, cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}
export default History;
