import behavioralHealth from "./behavioral-health";
import treatmentPlanning from "./treatment-planning";
import compliance from "./compliance";
import humanResource from "./human-resource";

const ADVISORS = [
  behavioralHealth,
  treatmentPlanning,
  compliance,
  humanResource,
];

export default ADVISORS;
