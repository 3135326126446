import { ChatAlt2Icon } from "@heroicons/react/solid";

const obj = {
  title: "Compliance",
  desc: "Chat with our Compliance AI",
  category: "Advisor",
  Icon: ChatAlt2Icon,
  permissions: ["user"],
  fromColor: "gray-500",
  to: "/ai/advisors/compliance",
  api: "/ai/advisors/compliance",
  welcomeMessage: `I can assist you with compliance-related questions in the behavioral health, mental health, and addiction treatment industry. This includes topics such as HIPAA, patient privacy, documentation requirements, billing and coding, accreditation standards, and other regulatory requirements. I can provide information, guidance, and resources to help you understand and navigate these compliance issues. How can I assist you today?`,
};

export default obj;
