import { reaction } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { fetchHistories, updateHistory } from "../api.service";
import ActiveArchivedToggle from "./History/ActiveArchivedToggle";
import HistoryConfirmationModal from "./History/HistoryConfirmationModal";
import HistoryList from "./History/HistoryList";

const History = inject(
  "store",
  "navixScribeStore"
)(
  observer(({ store, navixScribeStore, toolType, toolAction }) => {
    const [currPage, setCurrPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [activeButton, setActiveButton] = useState("active");

    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState({
      title: "Remove",
      description: "remove",
    });

    const [editing, setEditing] = useState(false);
    const [historyId, setHistoryId] = useState(null);
    const [historyTitle, setHistoryTitle] = useState("");

    const setHistories = (histories, toolAction) => {
      if (toolAction === "Input") {
        navixScribeStore.setInputHistories(histories);
      } else if (toolAction === "ClinicalNote") {
        navixScribeStore.setClinicalNotesHistories(histories);
      } else if (toolAction === "AskQuestion") {
        navixScribeStore.setQuestionHistories(histories);
      } else if (toolAction === "Summary") {
        navixScribeStore.setSummaryHistories(histories);
      }
    };

    const getHistories = (toolAction) => {
      if (toolAction === "Input") {
        return navixScribeStore.inputHistories;
      } else if (toolAction === "ClinicalNote") {
        return navixScribeStore.clinicalNotesHistories;
      } else if (toolAction === "AskQuestion") {
        return navixScribeStore.questionHistories;
      } else if (toolAction === "Summary") {
        return navixScribeStore.summaryHistories;
      }
    };

    async function fetchData(page = 1) {
      try {
        console.log("fetchData - fetching histories with page:", page);
        const { result, totalPage } = await fetchHistories(
          page,
          toolType,
          toolAction,
          activeButton === "archived"
        );
        console.log("fetchData - fetched result:", result);
        setHistories(result, toolAction);
        setPageCount(totalPage);
        console.log(
          "fetchData - after setting histories, current inputActiveRecord:",
          navixScribeStore.inputActiveRecord
        );
      } catch (err) {
        toast.error("Can't fetch history data");
      }
    }

    useEffect(() => {
      const disposeReaction = reaction(
        () => navixScribeStore.triggerSection,
        (triggerSection) => {
          console.log("reaction - triggerSection changed:", triggerSection);
          if (triggerSection === toolAction) {
            fetchData();
            navixScribeStore.setTriggerSection("");
            console.log(
              "reaction - after fetchData, current inputActiveRecord:",
              navixScribeStore.inputActiveRecord
            );
          }
        }
      );

      return () => {
        disposeReaction();
      };
    }, []);

    useEffect(() => {
      fetchData();
    }, [activeButton]);

    const handlePageClick = async (event) => {
      setCurrPage(event.selected);
      await fetchData(event.selected + 1);
    };

    const handleEditTitle = (id, title) => {
      setEditing(true);
      setHistoryId(id);
      setHistoryTitle(title);
    };

    const handleCancelEdit = () => {
      setEditing(false);
      setHistoryId(null);
      setHistoryTitle("");
    };

    const handleTitleKeyDownAction = (e) => {
      if (e.key === "Enter") handleUpdateTitle();
      if (e.key === "Escape") handleCancelEdit();
    };

    const handleTitleChange = (e) => {
      setHistoryTitle(e.target.value);
    };

    const handleUpdateTitle = async () => {
      try {
        await updateHistory(historyId, { title: historyTitle });

        const histories = getHistories(toolAction).map((i) => {
          if (i._id === historyId) i.title = historyTitle;
          return i;
        });
        setHistories(histories, toolAction);

        if (navixScribeStore.inputActiveRecord._id === historyId) {
          navixScribeStore.setInputActiveRecord({
            ...navixScribeStore.inputActiveRecord,
            title: historyTitle,
          });
        } else if (navixScribeStore.outputActiveRecord._id === historyId) {
          navixScribeStore.setOutputActiveRecord({
            ...navixScribeStore.outputActiveRecord,
            title: historyTitle,
          });
        }

        toast.success("Successfully updated history title.");
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        handleCancelEdit();
      }
    };

    const handleShowModal = (id, action) => {
      if (action === "archive") {
        setModalInfo({ title: "Archive", description: "archive" });
      } else if (action === "unarchive") {
        setModalInfo({ title: "Unarchive", description: "unarchive" });
      } else {
        setModalInfo({ title: "Remove", description: "remove" });
      }
      setHistoryId(id);
      setShowModal(true);
    };

    const handleActionModal = async (id, action) => {
      try {
        action = action.toLowerCase();
        await updateHistory(id, { action });
        setCurrPage(0);
        setShowModal(false);
        await fetchData();
        toast.success(`Successfully ${action} history.`);
      } catch (err) {
        toast.error("Something went wrong, kindly contact administrator");
      }
    };

    const handleClickActiveRecord = (id) => {
      navixScribeStore.setActiveHistory(id, toolAction);
    };

    return (
      <>
        <div>
          <div className="flex justify-between items-center">
            <h1 className="text-gray-500 text-sm sm:text-base">
              Previously Submitted
            </h1>
            <ActiveArchivedToggle
              activeButton={activeButton}
              setActiveButton={setActiveButton}
            />
          </div>

          <div className="space-y-2">
            <HistoryList
              activeButton={activeButton}
              currentItems={getHistories(toolAction)}
              handleEditTitle={handleEditTitle}
              handleShowModal={handleShowModal}
              editing={editing}
              historyId={historyId}
              historyTitle={historyTitle}
              handleTitleChange={handleTitleChange}
              handleTitleKeyDownAction={handleTitleKeyDownAction}
              handleUpdateTitle={handleUpdateTitle}
              handleCancelEdit={handleCancelEdit}
              handleClickActiveRecord={handleClickActiveRecord}
              outputActiveRecord={navixScribeStore.outputActiveRecord}
              inputActiveRecord={navixScribeStore.inputActiveRecord}
            />
          </div>
          <StyledReactPaginate
            forcePage={currPage}
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
          />
        </div>
        <HistoryConfirmationModal
          isModalOpen={showModal}
          setIsModalOpen={setShowModal}
          id={historyId}
          handleAction={handleActionModal}
          modalInfo={modalInfo}
        />
      </>
    );
  })
);

export const StyledReactPaginate = styled(ReactPaginate)`
  margin-top: 12px;
  display: flex;
  justify-content: end;
  flex-wrap: nowrap;
  column-gap: 5px;

  text-align: center;
  color: #44515a;
  font-size: 12.465px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.581px;

  li a {
    border: 0.78px solid #d8d8d8;
    background: white;
    border-radius: 4px;
    padding: 3px 12px;
    cursor: pointer;
  }

  .selected a {
    border: 0.78px solid #44515a;
  }
`;

export default History;
